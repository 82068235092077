//------------------------------//
//-- COMPATIBILITE IE 10 ONLY --//
//------------------------------//

/**
 * Ajout de la fonction startsWith aux objets de type String sur IE 10
 */ 
if (typeof String.prototype.startsWith !== 'function') {
	Object.defineProperty(String.prototype, 'startsWith', {
		value: function(search, rawPos) {
			let pos = rawPos > 0 ? rawPos | 0 : 0;
			return this.substring(pos, pos + search.length) === search;
		},
		writable: true,
		configurable: true
	});
}