/* Section pour virer les warnings de netbeans concernant les variables globales*/
/* global toastr */
/* global CATEGORIE_SALARIALE*/

"use strict";

import { Ajax } from './ajax.js';
import { Dom } from './dom.js';
import { Debug } from './debug.js';
import { ProjetCommon } from './projetCommon.js';
import { CadreInfoEmbauche } from './itemCadreInfoEmbauche.js';
import { PFHBaseClass } from './pfhBaseClass.js';
import { ProjetSalariePageType } from './projetSalariePageType.js';
import { ProjetSalariePageDetail } from './projetSalariePageDetail.js';

window.STEP = {
	TYPE: 1,
	CLIENT: 2,
	CLIENT_TROUVE: 3,
	CLIENT_INSCRIPTION: 4,
	DETAIL: 5,
	ANNEXES: 6,
	EMBAUCHE: 7,
	EMBAUCHE_INSCRIPTION_INTERMITTENT: 8,
	EMBAUCHE_SAISIE_CONTRAT: 9,
	RECAP: 11
};

class ProjetSalarie extends PFHBaseClass {
	//---------------------//
	//-- DONNEES MEMBRES --//
	//---------------------//
	
	m_fMsg = {
		AjoutClient: {
			nomClient: { required: "Merci de renseigner le nom de la société" },
			codePostal: { required: "Merci de renseigner le code postal" }
		},
		
		AjoutFrais: {
			"libelle": { required: "Veuillez indiquer le libelle de la facture" },
			"cout-ht": { required: "Veuillez indiquer le cout HT de la facture", number: "Veuillez saisir un montant valide" },
			"cout-ttc": { required: "Veuillez indiquer le cout TTC de la facture", number: "Veuillez saisir un montant valide" },
			"tva": { required: "Veuillez indique la TVA" },
			"type-frais": { required: "Merci d'indiquer le type de facture" }
		},
		
		InscriptionClient: {
			identifiant: { required: "Merci d'écrire un identifiant" },
			mot_de_passe: { required: "Merci d'écrire un mot de passe" },
			nom_societe: { required: "Merci d'écrire le nom de la structure" },
			forme_juridique: { required: "Merci d'écrire une forme juridique" },
			fonction_contact: { required: "Merci de choisir la fonction" },
			id_civilite_contact: { required: "Merci de choisir sa civilité" },
			email_contact: { required: "Merci d'écrire son email", email: "L'email n'est pas valide", different_utilisateur: "Merci d'écrire un email différent de votre email" },
			telephone_contact: { required: "Merci d'écrire un numéro de téléphone", number: "Merci d'écrire uniquement des chiffres" },
			nom_contact: { required: "Merci d'écrire le nom du contact" },
			prenom_contact: { required: "Merci d'écrire le prénom du contact" },
			rue1_ad: { required: "Merci d'écrire une adresse" },
			code_postal_ad: { required: "Merci d'écrire un code postal" },
			ville_ad: { required: "Merci d'écrire une ville" },
			id_pays_ad: { required: "Merci de choisir un pays", number: "Merci de choisir un pays" },
			categorie: { required: "Merci de choisir un type de structure" },
			siret: { required: "Merci d'écrire son siret" },
			ape: { required: "Merci d'écrire son code ape" }
		},
		
		InscriptionClientAdresseCourrier: {
			nom_societe_ad_courrier: { required: true, alphanumeric: true, messages: { required: "Merci d'écrire le nom de correspondance." } },
			rue1_ad_courrier: { required: true, alphanumeric: true, messages: { required: "Merci d'écrire une adresse." } },
			rue2_ad_courrier: { alphanumeric: true },
			rue3_ad_courrier: { alphanumeric: true },
			code_postal_ad_courrier: { required: true, messages: { required: "Merci d'écrire le code postal." } },
			ville_ad_courrier: { required: true, messages: { required: "Merci d'écrire la ville." } },
			id_pays_ad_courrier: { required: true, number: true, messages: { required: "Merci de choisir le pays." } }
		}
	}
	
	m_fRules = {
		AjoutClient: {
			nomClient: { required: true },
			codePostal: { required: true }
		},
	
		AjoutFrais: {
			"libelle": { required: true },
			"cout-ht": { required: true, number: true },
			"cout-ttc": { required: true, number: true },
			"tva": { required: true, number: true },
			"type-frais": { required: true }
		},

		InscriptionClient: {
			nom_societe_ad: { required: true, alphanumeric: true },
			fonction_contact: { required: true },
			siret: { siret: true },
			tva_intra: { tva: true },
			ape: { ape: true },
			id_civilite_contact: { required: true },
			email_contact: { required: true, email: true, valid_email: true, different_utilisateur:$('#form-projet-inscription-client input[name="email_utilisateur"]').val() },
			telephone_contact: { required: true, telephone: true, number:true },
			nom_contact: { required: true, alphanumeric: true },
			prenom_contact: { required: true, alphanumeric: true },
			rue1_ad: { required: true, alphanumeric: true },
			rue2_ad: { alphanumeric: true },
			rue3_ad: { alphanumeric: true },
			code_postal_ad: { required: true },
			ville_ad: { required: true },
			id_pays_ad: { required: true, number: true },
			categorie: { required: true }
		}
	};

	m_baseCalculFrais = null; // Variable contenant la dernière base de calcul utilisé pour les frais ("cout-ht", "cout-ttc", "tva")	
	m_templateAnnexe = null; // Template d'une ligne d'annexe
	m_templateFrais = null; // Template d'une ligne de frais

	//------------------//
	//-- CONSTRUCTEUR --//
	//------------------//
	
	constructor() {
		super(); // Appelle le constructeur de PFHBaseClass	
		
		const self = this; // Capturer le contexte de `this`

		// Configuration globale
		const $projetCommon = new ProjetCommon();
		
		// Page de choix du type de demande
		const $pageType = new  ProjetSalariePageType();
		
		// Page du détail de la demande
		const $pageDetail = new ProjetSalariePageDetail({
				typeDemande: $projetCommon.typeDemande(),
				tva: $projetCommon.tva()
			});
			
		this.m_item = {
			projetCommon: $projetCommon,
			pageType: $pageType,
			pageDetail: $pageDetail
		};
		
		this.m_sel = {
			currentPage: `${this.sCreationProjet()} .step.active`, // Page active SELECTEUR DYNAMIQUE

			pageEtape2ClientCreation: '#projet-client-creation',
			pageEtape2ClientTrouve: '#projet-client-trouve',

			formRechercheClient: '#form-ajout-client-projet', // ETAPE 2-1 : FORMULAIRE d'ajout d'un nouveau client
			formInscriptionClient: '#form-projet-inscription-client', // ETAPE 2-2 : FORMULAIRE contenant les informations d'un nouveau client

			formAnnexe: '#form-annexe-projet', // ETAPE 4 : FORMULAIRE d'ajout d'une annexe
			formFrais: '#form-frais-projet', // ETAPE 4 : FORMULAIRE d'ajout d'un frais
			listeTelechargementFrais: '#projet-list-frais', // ETAPE 4
			tableTelechargementFrais: '#projet-list-frais-table', // ETAPE 4
			listeTelechargementAnnexe: '#projet-list-annexe', // ETAPE 4
			tableTelechargementAnnexe: '#projet-list-annexe-table' // ETAPE 4
		};

		$projetCommon.setDefaultLieu(this.iPageDetail().I('fdLieu').val());
		$projetCommon.setDefaultCodePostal(this.iPageDetail().I('fdCodePostal').val());
		$projetCommon.setDefaultVille(this.iPageDetail().I('fdVille').val());
		$projetCommon.setDefaultPays(this.iPageDetail().I('fdPays').find('option:selected').text());
		
		//-----------------------------------------//
		//-- Initialisation des actions communes --//
		//-----------------------------------------//

		// ACTION : Click sur le bouton étape suivante ou bien les étapes de projet
		$(this.sCreationProjet() + " button.next-step").click((p_event) => this.onBpNextStepClicked(p_event));

		// ACTION : La TVA à été mise à jours
		this.iCreationProjet().on('tvaUpdated', (p_event, p_tva) => {
			this.iPageDetail().setTva(p_tva);
		});

		//-------------------------------//
		//-- ETAPE 1 : TYPE DE DEMANDE --//
		//-------------------------------//

		// ACTION : Le type de demande à été mis à jours
		this.iPageType().item().on('typeDemandeUpdated', (p_event, p_typeDemande, p_numDemande, p_idDemande) => {
				this.onTypeDemandeUpdated(p_event, p_typeDemande, p_numDemande, p_idDemande);
			});

		//-------------------------------------//
		//-- ETAPE 2-1 : LE CLIENT SELECTION --//
		//-------------------------------------//
			
		Dom.initFormValidation(
			this.I('formRechercheClient'), 
			'AppEmbauche/chercheClient', 
			this.m_fRules.AjoutClient, 
			this.m_fMsg.AjoutClient);
			
		this.I('formRechercheClient').on("submit-success", (p_event, p_result) => {
			
			// On efface le formulaire de création de client
			Dom.clearForm(this.iFormInscriptionClient(), 'idDemande');

			// Si on à un ou plusieurs clients correspondant à la recherche
			// On remplis les infos et on passe à l'étape suivante					
			if (p_result.items.length > 0) {
				var info = p_result.items[0];

				var contact = [info.contactCivilite, info.contactPrenom, info.contactNom].filter(Boolean).join(' ');
				if (!contact) contact = 'n/d';

				var container = $(this.sCreationProjet() + ' #projet-client-trouve');
				container.attr('data-idClient', info.id_client);
				container.attr('data-societe', info.adresseNomSociete);
				container.attr('data-codePostal', info.adresseCodePostal);
				container.find('.nom_societe span').html(info.adresseNomSociete);
				container.find('.adresse span').html([info.adresseRue1, info.adresseRue2, info.adresseRue3].filter(Boolean).join(' - '));
				container.find('.cp span').html(info.adresseCodePostal + ' / ' + [info.adresseVille, info.paysNom].filter(Boolean).join(' / '));
				container.find('.contact span').html(contact);

				// On pré-remplis le formulaire de création de client
				$(this.m_sel.formInscriptionClient + ' input[name="nom_societe_ad"]').val(info.adresseNomSociete);
				$(this.m_sel.formInscriptionClient + ' input[name="code_postal"]').val(info.adresseCodePostal);
				if (info.contactNom === null) info.contactNom = "";
				if (info.contactPrenom === null) info.contactPrenom = "";
				$(this.m_sel.formInscriptionClient + ' input[name="nom_contact"]').val(info.contactNom);
				$(this.m_sel.formInscriptionClient + ' input[name="prenom_contact"]').val(info.contactPrenom);

				this.changeStep(STEP.CLIENT_TROUVE);
			} else { // Si aucun client ne correspond à la recherche on en ajoute un nouveau
				this.afficheEtapeAjouteNouveauClient(); 
			}
		});
		
		/**
		 * Autocomplete pour la recherche de client
		 * Les input dont le nom est nomclient dans creation-projet recevera cette fonctionnalité A REVOIR RISQUE
		 */
		$(this.sCreationProjet() + ' input[name="nomClient"].autocomplete').each(function() {
				$(this).autocomplete({
					
					// Source de l'autocomplete
					source: function(p_request, p_response) {
						Ajax.postAsync (
							'AppEmbauche/chercheClient', {
								nomClient: p_request.term
							},

							(p_result) => {
								if (p_result.items) {
									// On ajoute chaques élément du résultat à l'autocomplete
									p_response($.map(p_result.items, (p_item) => {
											return { label: p_item.adresseNomSociete, ref: p_item.id_client };
										})
									);
								}
							},

							(p_result) => Ajax.defaultAsyncJSONErrorCallback(p_result)
						);
					},
					
					// Action lors de la selection d'un client
					select: (p_event, p_ui) => {
						self.updateClient(p_ui.item.ref, null, STEP.DETAIL); 
					},
					
					// on effectue la recherche à partir de 3 caractères
					minLength: 3
				});
			}
		);

		//---------------------------------------------//
		//-- ETAPE 2-2 : LE CLIENT AJOUT D'UN CLIENT --//
		//---------------------------------------------//

		/** Configuration du formulaire d'inscription d'un nouveau client dans un projet */
		Dom.initFormValidation(
			this.iFormInscriptionClient(), 
			'AppClient/inscription', 
			this.m_fRules.InscriptionClient, 
			this.m_fMsgInscriptionClient);

		/** ACTION : Clique sur enregistrer le client lors de la saisie d'un nouveau client*/
		this.iFormInscriptionClient().on('submit-success', (p_event, p_result) => this.onClientAjouterSuccess(p_event, p_result));

		/** ACTION : Clique sur ajout d'un nouveau client */
		$('#btn-ajouter-nouveau-client').click(() => {
			this.afficheEtapeAjouteNouveauClient(); 
		});

		/** ACTION : Si on clique sur utilisé le client qui à été trouvé */
		$('#btn-utilise-client-trouve').click(this.onBtnUtiliserClientTrouveClicked);

		$('#adresse-courrier-differente').change(() => {
			if ($(this).is(':checked')) {
				Dom.modifyFormRules(this.iFormInscriptionClient(), this.m_fRules.InscriptionClientAdresseCourrier, 'add');
			} else {
				Dom.modifyFormRules(this.iFormInscriptionClient(), this.m_fRules.InscriptionClientAdresseCourrier, 'remove');
			}
		});

		$('#adresse-courrier-differente').trigger('change');

		//-------------------------//
		//-- ETAPE 3 : LE DETAIL --//
		//-------------------------//

		this.iPageDetail().iFormDetail().on("submit-success", (p_event, p_result) => {
			const $projetCommon = this.iProjetCommon();
			const $listingCadreInfoEmbauches = $projetCommon.iListingCadreInfoEmbauche();
			
			// On met à jours les valeures par defaut de l'embauche
			$projetCommon.setDefaultLieu(this.iPageDetail().I('fdLieu').val());
			$projetCommon.setDefaultCodePostal(this.iPageDetail().I('fdCodePostal').val());
			$projetCommon.setDefaultVille(this.iPageDetail().I('fdVille').val());
			$projetCommon.setDefaultPays(this.iPageDetail().I('fdPays').find('option:selected').text());		
			
			// Si il y à déja des embauches, on met a jours les cadres infos
			$listingCadreInfoEmbauches.refreshAllCadreInfoEmbauche();
			
			// Si une embauche à été crée on l'ajoute à la liste des embauches
			$listingCadreInfoEmbauches.insertCadreInfoEmbauche(p_result.htmlContrat);
				
			// On affiche le bouton suivant
			Dom.setVisibility($(this.iProjetCommon().m_sel.boutonsChoixEtapes), true);

			this.changeStep(STEP.ANNEXES);
		});
	
		//-----------------------//
		//-- ETAPE 4 : ANNEXES --//
		//-----------------------//

		/** ACTION : On clique sur ajouter une facture (celui qui fait apparaitre le popin) */
		$('#btn-ajouter-annexe-facture').click(() => {
			$('#popin-ajout-facture').modal('show'); 
		});

		/** ACTION : On clique sur ajouter une facture */
		$('#popin-ajout-facture-valider').click(() => { 
			$(this.m_sel.formFrais).submit(); 
		});

		/**
		 * ACTION : FRAIS : Affiche le nom du fichier chargé dans la zone de texte lorsque l'on à séléctionné un fichier
		 */
		$(this.m_sel.formFrais + ' input[name="file"]').change((p_event) => {
			$(this.m_sel.formFrais + ' input[name="file-op"]').val($(p_event.currentTarget).val());
		});

		/**
		 * ACTION : FRAIS : Lance le chargement d'un fichier si on clique sur la zone de texte
		 */
		$(this.m_sel.formFrais + ' input[name="file-op"]').click(() => {
			$(this.m_sel.formFrais + ' input[name="file"]').trigger('click');
		});

		/**
		 * ACTION : FRAIS : Mise à jours automatique des montants si on change le HT, le TTC ou bien la TVA dans la page frais
		 * @param {Event} p_event
		 */
		this.iFormFrais().on('change', '[name="cout-ht"],[name="cout-ttc"],[name="tva"]', (p_event) => {
			// On récupère les paramètres
			var fieldHt = $(this.m_sel.formFrais + ' [name="cout-ht"]');
			var fieldTtc = $(this.m_sel.formFrais + ' [name="cout-ttc"]');
			var fieldTva = $(this.m_sel.formFrais + ' [name="tva"] option:selected');
			var ht = parseFloat(fieldHt.val());
			var ttc = parseFloat(fieldTtc.val());
			var tva = parseFloat(fieldTva.text());

			if (fieldTva.val() !== '') {
				var name = (this.name === 'tva' ? this.m_baseCalculFrais : this.name);
				this.m_baseCalculFrais = name;
				if (name === 'cout-ttc' && !isNaN(ttc)) {
					ht = ttc / (1 + tva / 100);
					fieldHt.val(parseInt(ht * 100) / 100);
				} else if (!isNaN(ht)) {
					ttc = ht * (1 + tva / 100);
					fieldTtc.val(parseInt(ttc * 100) / 100);
					fieldTtc.validate();
				}
				
				fieldHt.trigger('focusout');
				fieldTtc.trigger('focusout');
			}
		});

		// CONFIGURATION : Configuration du formulaire d'upload de frais
		this.iFormFrais().validate({
			errorElement: 'div',
			focusInvalid: false,
			rules: this.m_fRules.AjoutFrais,
			messages: this.m_fMsgAjoutFrais,
			submitHandler: () => { self.onUploadFraisSubmitted(); },
			invalidHandler: Dom.defaultAsyncInvalidHandler
		});

		/**
		 * ACTION : On clique sur ajouter un document
		 * @param {Event} p_event
		 */
		$('#btn-ajouter-annexe-document').click((p_event) => {
			p_event.preventDefault();

			$(this.m_sel.formAnnexe + ' input[type="file"]').trigger('click');
		});

		// ACTION : ANNEXE : Fonction appelée lorsque l'on demande l'UPLOAD d'une annexe
		$(this.m_sel.formAnnexe + ' input[type="file"]').change((p_event) => this.uploadAnnexe(p_event));

		/**
		 * Gestion des clicks sur les éléments dynamiques
		 * @param {Event} p_event
		 */
		$(document).on('click', '[name="supprimer-frais"], [name="supprimer-annexe"]', (p_event) => {
			const $this = $(this);
			const name = $(p_event.currentTarget).attr('name');

			switch (name) {
				case 'supprimer-frais' :
					this.removeFile('frais', p_event.currentTarget);
					break;
				case 'supprimer-annexe' :
					this.removeFile('annexe', p_event.currentTarget);
					break;
			}
		});

		//------------------------//
		//-- ETAPE 5 : EMBAUCHE --//
		//------------------------//

		// ACTION : Un cadreInfoEmbauche à été supprimé
		this.iProjetCommon().iListingCadreInfoEmbauche().item().on('cadreInfoEmbaucheRemoved', (p_event) => this.onCommonCadreInfoEmbaucheRemoved(p_event));

		// ACTION : On à annuler l'édition d'un contrat d'embauche
		this.iProjetCommon().item().on('contratEmbaucheClosed', (p_event) => this.onCommonContratEmbaucheClosed(p_event));

		// ACTION : Une demande d'édition d'un contrat d'embauche à été effectuée
		this.iEditionContrat().item().on('contratEmbaucheEdited', (p_event, p_data) => this.onCommonContratEmbaucheEdited(p_event, p_data));

		//-----------------------------//
		//-- ETAPE 6 : RECAPITULATIF --//
		//-----------------------------//

		// ACTION : On clique sur étape suivante, finaliser ou bien étape 6	 
		$(this.sCreationProjet() + ' .projet-finaliser').click(this.onRecapMajRecapRequested);

		//------------------------------//
		//-- SCRIPTS D'INITIALISATION --//
		//------------------------------//

		// ANNEXES
		// On sauvegarde, puis on rend invisible les templates que l'on va utiliser
		this.m_templateAnnexe = this.getTemplateFromId(this.m_sel.tableTelechargementAnnexe + ' [role="template"]');
		this.m_templateFrais = this.getTemplateFromId(this.m_sel.tableTelechargementFrais + ' [role="template"]');

		// Initialisation des flatpikers
		flatpickr('#fp_date_debut', {dateFormat: 'd/m/Y', altFormat: 'Y-m-d'});
		flatpickr('#fp_date_fin', {dateFormat: 'd/m/Y', altFormat: 'Y-m-d'});	
	}
	
	//---------------------------//
	//-- ACCESSEURS SELECTEURS --//
	//---------------------------//
	
	sCreationProjet() {
		return this.iProjetCommon().sCreationProjet();
	}
	
	//---------------------//
	//-- ACCESSEURS ITEM --//
	//---------------------//

	iCreationProjet() {
		return this.iProjetCommon().iCreationProjet();
	}

	iEditionContrat() {
		return this.iProjetCommon().iEditionContrat();
	}

	iPageDetail() {
		return this.m_item.pageDetail;
	}

	iPageType() {
		return this.m_item.pageType;
	}
	
	iProjetCommon() {
		return this.m_item.projetCommon;
	}

	iFormInscriptionClient() {
		return this.I('formInscriptionClient');
	}
	
	iFormAnnexe() {
		return this.I('formAnnexe');
	}
	
	iFormFrais() {
		return this.I('formFrais');
	}
	
	iListeTelechargementFrais() {
		return this.I('listeTelechargementFrais');
	}
	
	iTableTelechargementFrais() {
		return this.I('tableTelechargementFrais');
	}
	
	iListeTelechargementAnnexe() {
		return this.I('listeTelechargementAnnexe');
	}
	
	iTableTelechargementAnnexe() {
		return this.I('tableTelechargementAnnexe');
	}
		
	//------------//
	//-- COMMUN --//
	//------------//

	getTemplateFromId($p_idTemplate) {
		const item = $($p_idTemplate);
		const template = $('<p/>').html(item).html();
		Dom.setVisibility(item, false);
		return template;
	}
		
	changeStep(p_choosen) {
		//on déduit l'ID de l'étape actuelle depuis le nom de la classe
		const currentPage = this.D('currentPage');
		const currentStep = currentPage.attr('data-step');
		
		// Si on demande d'afficher la même page, on ne fait rien
		if (currentStep === p_choosen) {
			return;
		} 
		
		let nextPage = $(this.sCreationProjet() + ' .step.step' + p_choosen);
		
		this.updateStep(currentPage, nextPage);
	}
		
	nextStep() {
		//on déduit l'ID de l'étape actuelle depuis le nom de la classe
		const currentPage = this.D('currentPage');
				
		let nextPage = currentPage.next();
		
		this.updateStep(currentPage, nextPage);
	}
		
	/**
	 * Séléctionne une étape spécifique ou bien passe à l'étape suivante
	 * @param p_currentPage Ancienne page
	 * @param p_nextPage page à afficher
	 */
	updateStep(p_currentPage, p_nextPage) {
		
		// On récupère le numéro de la prochaine étape
		let numNextStep = $(this.sCreationProjet() + ' #' + p_nextPage[0].id).attr('data-step');
		
		// Active les boutons de l'étapes suivante plus les précédents
		// ATTENTION : l'ordre est important du plus grand au plus petit
		switch (parseInt(numNextStep)) {
			case 11: 
				$('#projet-etapes button[data-step="11"]').attr('disabled', false); // Etape Récapitulatif
			case 7: 
				$('#projet-etapes button[data-step="7"]').attr('disabled', false); // Etape Embauche
			case 6:
				$('#projet-etapes button[data-step="6"]').attr('disabled', false); // Etape Annexe
			case 5:
				$('#projet-etapes button[data-step="5"]').attr('disabled', false); // Etape Détail
			case 2:
				$('#projet-etapes button[data-step="2"]').attr('disabled', false); // Etape Client
			case 1:
				$('#projet-etapes button[data-step="1"]').attr('disabled', false); // Etape Type
		}
		
		// Cache l'ancienne page et affiche la nouvelle
		p_currentPage.removeClass('active');
		p_nextPage.addClass('active');
		
		// On scroll en haut de la page
		$(window).scrollTop(0);
	}

	/**
	 * ACTION : Click sur le bouton étape suivante ou bien les étapes de projet
	 * @param p_event event
	 */
	onBpNextStepClicked(p_event) {
		p_event.preventDefault();

		// On récupère le numéro de l'étape que l'on à cliqué
		let numNextStep = $(p_event.currentTarget).attr('data-step');
		
		if (numNextStep) {
			this.changeStep(numNextStep); 
		}
		else {
			this.nextStep();
		}
	}
	
	//------------------------//
	//-- LE TYPE DE DEMANDE --//
	//------------------------//

	/**
	 * Un choix de type de demandeà été effectué
	 * @param {Event} p_event Evenement associé
	 * @param {TYPE_SOCIETE} p_typeDemande type de la demande
	 * @param {number} p_numDemande numero de la demande associée
	 * @param {number} p_idDemande id crypté de la demande associée
	 */
	onTypeDemandeUpdated (p_event, p_typeDemande, p_numDemande, p_idDemande) {
		this.iPageDetail().setTypeDemande(p_typeDemande);
		this.iProjetCommon().setTypeDemande(p_typeDemande); // On configure le tout en fonction du type de demande
		this.iProjetCommon().setNumDemande(p_numDemande); // On modifie l'affichage avec le numero de demande
		this.iProjetCommon().setIdDemande(p_idDemande); // On modifie l'url de la page
		
		this.nextStep(); // On passe à l'étape suivante
	}

	//---------------//
	//-- LE CLIENT --//
	//---------------//
	
	/**
	 * Remplis le formulaire d'ajout d'un nouveau client avec les valeures saisie à l'étape 2
	 * Une fois effectuée on ouvre le formulaire
	 */
	afficheEtapeAjouteNouveauClient() {
		//Réinitialise les elements de formulaire contenants la donnée data-default
		Dom.defaultInput(this.iFormInscriptionClient());
		
		// On initialise le nom de societe et le code postal du formulaire
		Dom.fillForm($(this.m_sel.pageEtape2ClientCreation), {
			nom_societe_ad: $(this.sCreationProjet() + ' input[name="nomClient"]').val(),
			code_postal_ad: $(this.sCreationProjet() + ' input[name="codePostal"]').val()
		});
		this.changeStep(STEP.CLIENT_INSCRIPTION);
	}
	
	/**
	 * ATTENTION ASYNCHRONE
	 * Met a jours la demande avec le client dont l'ID est passé en paramètre
	 * La TVA est également modifiée selon le client
	 * Si tout se passe bien, execute le callback passé en paramètre
	 * @param {type} p_idClient
	 * @param {type} p_callBackOnSuccess callback appelé en cas de succès
	 * @param {type} p_nextStepOnSuccess étape de redirection en cas de succès
	 * @returns {undefined}
	 */
	updateClient(p_idClient, p_callBackOnSuccess, p_nextStepOnSuccess) {
		// Paramètres par défaut (compatible IE)
		if (p_nextStepOnSuccess === undefined) p_nextStepOnSuccess = null;

		$.blockUI();		
		Ajax.postAsync (
			'AppEmbauche/demandeMajClient', {
				idClient: p_idClient
			},
			
			(p_result) => {
				// La mise à jours d'un client peut entrainer la modification de la TVA
				this.iProjetCommon().setTva(p_result.tva);
				
				if (p_result.lieuParDefaut !== '') {
					var lieuItem = this.iPageDetail().I('fdLieu');
					if (lieuItem.val() === '') {
						lieuItem.val(p_result.lieuParDefaut);
					}
				}
			
				if (p_result.codePostalParDefaut !== '') {
					var codePostalItem = this.iPageDetail().I('fdCodePostal');
					if (codePostalItem.val() === '') {
						codePostalItem.val(p_result.codePostalParDefaut);
					}
				}
				
				if (p_result.villeParDefaut !== '') {
					var villeItem = this.iPageDetail().I('fdVille');
					if (villeItem.val() === '') {
						villeItem.val(p_result.villeParDefaut);
					}
				}
				
				if (p_result.budgetHTParDefaut > 0) {
					var htItem = this.iPageDetail().I('fdPrixHT');
					if (parseFloat(htItem.val()) === 0) {
						htItem.val(p_result.budgetHTParDefaut);
						htItem.trigger('change');
					}
				}
				
				if (p_result.budgetTTCParDefaut > 0) {
					var ttcItem = this.iPageDetail().I('fdPrixTTC');
					if (parseFloat(ttcItem.val()) === 0) {
						ttcItem.val(p_result.budgetTTCParDefaut);
						ttcItem.trigger('change');
					}
				}
				
				if (p_callBackOnSuccess !== null) {
					p_callBackOnSuccess(p_result);
				}
				
				if (p_nextStepOnSuccess !== null) {
					this.changeStep(p_nextStepOnSuccess);
				}
				
				$.unblockUI();
			},
			
			(p_result) => Ajax.defaultAsyncJSONErrorCallback(p_result, UNBLOCK_UI)
		);	
	}

	//---------------------//
	//-- ANNEXE ET FRAIS --//
	//---------------------//
	
	/**
	 * Télécharge un fichier nommé file dans un formulaire
	 * @param {type} p_idForm
	 * @param {type} p_idTable
	 * @param {type} p_uploadFunc
	 * @param {type} p_createItemfunc
	 * @returns {undefined}
	 */
	uploadFormFile(p_idForm, p_idTable, p_uploadFunc, p_createItemfunc) {
		$.blockUI();
		
		var form = $(p_idForm);

		// création des données formulaires à envoyer
		var formData = new FormData();

		// On ajoute les paramètres contenues dans les input et les select du formulaire
		form.find('input,select').each(function() {
			formData.append($(this).attr('name'), $(this).val()); 
		});

		// On ajoute le fichier à uploader sous le nom de paramètre 'userfile'
		var files = $(form).find('[name="file"]').prop('files');
		formData.append('userfile', files[0]);
				
		// On upload le fichier
		Ajax.upload(
			p_uploadFunc, 
			formData, 
			
			(p_result) => {
				if (p_result.success) {
					var item = p_createItemfunc(p_result, form);
					
					// On créer une nouvelle ligne et on l'ajoute
					$(p_idTable).append(item);

					// On a ajouter un élément, on cache donc le 'aucune annexe'
					$(p_idTable + ' tr.empty-table').addClass('d-none');

					// On vide le formulaire
					Dom.clearForm(form);

					toastr.success(p_result.message);						
				}
				else {
					toastr.error(p_result.message); 
				}
			}
		);
		
		$.unblockUI();
	}
	
	uploadAnnexe() {
		this.uploadFormFile(
			this.m_sel.formAnnexe, 
			this.m_sel.tableTelechargementAnnexe, 
			'AppEmbauche/demandeAjouteDocumentAnnexe',
			(p_result, p_form) => {
				// On créer une nouvelle ligne et on l'ajoute
				var annexeLine = $(this.m_templateAnnexe.replace('$nom', p_result.fileName).replace('$ref', p_result.ref));
				annexeLine.removeAttr('role');

				return annexeLine;
				});

		// @TODO uniquement si l'upload se passe bien
		// On affiche le listing
		Dom.setVisibility(this.iListeTelechargementAnnexe(), true);
		
		return false;
	}

	onUploadFraisSubmitted() {
		this.uploadFormFile(
			this.m_sel.formFrais, 
			this.m_sel.tableTelechargementFrais, 
			'AppEmbauche/demandeAjouteDocumentFrais', 
			(p_result, p_form) => {
				var fraisLine = $(this.m_templateFrais
					.replace('$nom', p_form.find('[name="libelle"]').val())
					.replace('$base', p_form.find('[name="cout-ht"]').val())
					.replace('$tva', p_form.find('[name="tva"] option:selected').text())
					.replace('$ttc', p_form.find('[name="cout-ttc"]').val())
					.replace('$codet', p_form.find('[name="type-frais"] option:selected').text())
					.replace('$code', p_form.find('[name="type-frais"]').val())
					.replace('$ref', p_result.ref)
					.replace('$justificatif', p_result.name));
				fraisLine.removeAttr('role');

				return fraisLine;
				});
		
		// @TODO uniquement si l'upload se passe bien
		// On affiche le listing
		Dom.setVisibility(this.iListeTelechargementFrais(), true);
		
		// On ferme le popin
		$('#popin-ajout-facture').modal('hide');
		
		return false;
	}
	
	/**
	 * ATTENTION ASYNCHRONE
	 * Fonction appelée lorsque l'on souhaite supprimer un frais ou une annexe
	 * @param {type} p_type type de fichier : 'frais' ou 'annexe'
	 * @param {type} p_element
	 */
	removeFile(p_type, p_element) {
		p_element = $(p_element);
		
		$.blockUI();
		
		let action = '';
		switch (p_type) {
			case 'annexe' :
				action = 'AppEmbauche/demandeSupprimeDocumentAnnexe';
				break;
				
			case 'frais' :
				action = 'AppEmbauche/demandeSupprimeDocumentFrais';
				break;
			
			default :
				return;
		}
		
		Ajax.postAsync (
			action, {
				ref: p_element.attr('data-info')
			}, 
			
			(p_result) => {
				// Si on supprime le dernier élément dans le tableau on ne l'affiche plus
				if (p_element.parents('tbody').find('tr').length === 1) {
					Dom.setVisibility($('#projet-list-' + p_type), false);
				}

				// On supprime la ligne du tableau
				p_element.trigger('mouseout').parents('tr').remove();

				$.unblockUI();
				toastr.success(p_result.message); // On affiche le message
			},
			
			(p_result) => {
				$.unblockUI();
				Ajax.defaultAsyncJSONErrorCallback(p_result);
			}
		);
	}

	//--------------//
	//-- EMBAUCHE --//
	//--------------//

	/**
	 * Modifie l'affichage en fonction du mode de page d'embauche.
	 * La page embauche peut être en mode Edition de contrat ou bien en mode recherche de salarié
	 * @param {EMBAUCHE_MODE} p_pageMode mode souhaité
	 */
	embaucheSetPageMode(p_pageMode) {
		
		let $projetCommon = this.iProjetCommon();
		let $editionContrat = this.iEditionContrat();
		
		let rechercheSalarie = $projetCommon.m_item.rechercheSalarie;
		
		// ATTENTION : On affiche le formulaire dans tous les cas ??? bizare
		Dom.setVisibility($($editionContrat.m_sel.formEditionEmbauche), true);
		
		switch (p_pageMode) {
			case EMBAUCHE_MODE.RECHERCHE:				
				// On cache l'édition du contrat
				Dom.setVisibility($editionContrat.item(), false); 
				
				// On affiche la recherche
				rechercheSalarie.setVisibile(true); 
				rechercheSalarie.setAideVisibile(true);
				
				var nbEmbauches = $projetCommon.iListingCadreInfoEmbauche().embaucheCount();
				
				$('#projet-etapes button[data-step="11"]').prop('disabled', nbEmbauches===0); // on active ou non l'étape 11 selon si on à des contrats
			
				const hasEmbauche = (nbEmbauches > 0);
				Dom.setVisibility($projetCommon.iListingCadreInfoEmbauche().item(), hasEmbauche); // On affiche ou non le listing d'embauche				
				Dom.setVisibility($($projetCommon.m_sel.boutonsChoixEtapes), hasEmbauche); // On affiche ou non les boutons suivant ou précédent
				
				break;

			case EMBAUCHE_MODE.EDITION_CONTRAT:
				// On affiche l'édition du contrat
				Dom.setVisibility($editionContrat.item(), true);
				
				// On cache la recherche
				rechercheSalarie.setVisibile(false);
				rechercheSalarie.setAideVisibile(false);
				
				Dom.setVisibility($projetCommon.iCadreListingEmbauches(), false); // On cache le listing d'embauche
				Dom.setVisibility($($projetCommon.m_sel.boutonsChoixEtapes), false); // On cache les boutons suivant ou précédent
				break;
		}
	}

	onCommonContratEmbaucheEdited(p_event, p_data) {
		p_event.preventDefault();

		// Après avoir séléctionné un salarié on efface la recherche
		this.iProjetCommon().iRechercheSalarie().setResultVisibile(false);
				
		// Affichage de la page
		this.embaucheSetPageMode(EMBAUCHE_MODE.EDITION_CONTRAT);
	}
	
	//------------//
	//-- EVENTS --//
	//------------//

	/**
	 * ACTION : L'ajout d'un nouveau client est un succes
	 * On met a jours le client
	 * @param {type} p_event
	 * @param {type} p_result
	 */
	onClientAjouterSuccess(p_event, p_result) {
		function successCallback() {
			$(this.sCreationProjet() + ' [name="nomClient"]').val(p_result.nomClient); // On remplace tout ce qui contient nomClient (MERDIQUE REVOIR DOM)
			$(this.sCreationProjet() + ' [name="codePostal"]').val(p_result.codePostal); // On remplace dtout ce qui contient codePostal (MERDIQUE REVOIR DOM)
		}

		// Une fois le client créer on l'associe à la demande
		// ATTENTION ASYNCHRONE
		this.updateClient(p_result.idClient, successCallback, STEP.DETAIL);
	}
	
	/** ACTION : On clique sur utiliser le client trouvé */
	onBtnUtiliserClientTrouveClicked() {
		var container = $(this.sCreationProjet() + ' ' + this.m_sel.pageEtape2ClientTrouve);

		function successCallback() {
			$(this.sCreationProjet() + ' [name="nomClient"]').val(container.attr('data-societe'));
			$(this.sCreationProjet() + ' [name="codePostal"]').val(container.attr('data-codePostal'));
		}

		// ATTENTION ASYNCHRONE
		this.updateClient(container.attr('data-idClient'), successCallback, STEP.DETAIL);
	}
	
	/**
	 * ACTION : Validation du formulaire de recherche d'un client
	 */
	onClientRechercherFormSubmitted() {
		$.blockUI();
		
		Ajax.postAsync(
			
			'AppEmbauche/chercheClient', {
				searchStr: $(this.sCreationProjet() + ' input[name="nomClient"]').val(),
				codePostal: $(this.sCreationProjet() + ' input[name="codePostal"]').val()
			},
			
			(p_result) => {
				// On efface le formulaire de création de client
				clearForm(this.iFormInscriptionClient(), 'idDemande');

				// Si on à un ou plusieurs clients correspondant à la recherche
				// On remplis les infos et on passe à l'étape suivante					
				if (p_result.items.length > 0) {
					var info = p_result.items[0];

					var contact = [info.contactCivilite, info.contactPrenom, info.contactNom].filter(Boolean).join(' ');
					if (!contact) contact = 'n/d';

					var container = $(this.sCreationProjet() + ' #projet-client-trouve');
					container.attr('data-idClient', info.id_client);
					container.attr('data-societe', info.adresseNomSociete);
					container.attr('data-codePostal', info.adresseCodePostal);
					container.find('.nom_societe span').html(info.adresseNomSociete);
					container.find('.adresse span').html([info.adresseRue1, info.adresseRue2, info.adresseRue3].filter(Boolean).join(' - '));
					container.find('.cp span').html(info.adresseCodePostal + ' / ' + [info.adresseVille, info.paysNom].filter(Boolean).join(' / '));
					container.find('.contact span').html(contact);

					// On pré-remplis le formulaire de création de client
					$(this.m_sel.formInscriptionClient + ' input[name="nom_societe_ad"]').val(info.adresseNomSociete);
					$(this.m_sel.formInscriptionClient + ' input[name="code_postal"]').val(info.adresseCodePostal);
					if (info.contactNom === null) info.contactNom = "";
					if (info.contactPrenom === null) info.contactPrenom = "";
					$(this.m_sel.formInscriptionClient + ' input[name="nom_contact"]').val(info.contactNom);
					$(this.m_sel.formInscriptionClient + ' input[name="prenom_contact"]').val(info.contactPrenom);

					this.changeStep(STEP.CLIENT_TROUVE);
				}
				// Si aucun client ne correspond à la recherche on en ajoute un nouveau
				else { this.afficheEtapeAjouteNouveauClient(); }
				$.unblockUI();
			},
			
			(p_result) => {
				$.unblockUI();
				Ajax.defaultAsyncJSONErrorCallback(p_result);
			}
		);
	}

	onCommonContratEmbaucheClosed() {
		// On efface la recherche avant de revenir à la page de recherche
		//$(rechercheSalarie.sel.rechercheSalarieTxt).val(''); 
		this.iProjetCommon().iRechercheSalarie().setValue('');
		this.embaucheSetPageMode(EMBAUCHE_MODE.RECHERCHE);
	}
	
	onCommonCadreInfoEmbaucheRemoved() {
		// On rafraichis la page
		this.embaucheSetPageMode(EMBAUCHE_MODE.RECHERCHE);
	}
	
	onRecapMajRecapRequested(p_event) {
		// On désactive par défaut
		p_event.preventDefault();
		
		$.blockUI();
		Ajax.postAsync(
			
			'AppEmbauche/getTplRecapDemande', {
				idDemande: this.iProjetCommon().idDemande()
			},
			
			(p_result) => {
				if (p_result.success) {
					$('#projet-recap').html(p_result.html);
					$("#projet-recap button.next-step").click((p_event) => this.onBpNextStepClicked(p_event));
					$(this.iProjetCommon().m_sel.boutonsSauvegardeDemande + " button").click(this.iProjetCommon().onSaveDemandeClicked);

					this.changeStep(STEP.RECAP);					
				}
				else { 
					toastr.error(p_result.message); 
				}
				
				$.unblockUI();
			},
			
			(p_result) => {
				$.unblockUI();
				Ajax.defaultAsyncJSONErrorCallback(p_result);				
			}
		);
	}
};

//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------- DOCUMENT READY -------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//

$(document).ready(() => {	
	const pageCourante = Dom.getPageName();
	
	// Si on est pas sur la page de projet salarié on ne fait rien
	if (pageCourante !== "salarie-projet") {
		return;
	}
	
	// Initialisation du projet
	new ProjetSalarie();
});