"use strict";

/* Section pour virer les warnings de netbeans concernant les variables globales*/
/* global toastr */

import { Ajax } from './ajax.js';
import { Debug } from './debug.js';

export class Dom {

	/**
	 * @returns {String} le nom de la page actuelle
	 */
	static getPageName() {
		// On récupère l'alias de la page pour ne charger que le code de chaques pages
		const currentUrl = window.location.href;

		// Diviser l'URL en segments en utilisant '/' comme séparateur
		const urlSegments = currentUrl.split('/');

		// Vérifier que l'URL contient suffisamment de segments
		if (urlSegments.length > 3) {
			// Extraire la page actuelle de l'URL (index 3 dans ce cas)
			var pageName = urlSegments[3];

			// Si la pageName contient un '#' ou des paramètres '?', on les supprime
			pageName = pageName.split('#')[0]; // Supprime les fragments (partie après #)
			pageName = pageName.split('?')[0]; // Supprime les paramètres (partie après ?)

			return pageName;
		} else {
			// Retourner une chaîne vide ou un message par défaut si l'index est hors de portée
			return '';
		}
	}

	/**
	 * Désactive le retour chariot pour l'évenement passé en paramètre
	 * @param {Event} p_event Evenement associé
	 * @returns {Boolean}
	 */
	static disableCarriageReturn(p_event) {
		if ((p_event.keyCode || p_event.which) === 13) { 
			p_event.preventDefault();
			return false;
		}
	}

	/**
	 * Rend un element visible ou invisible en y ajoutant ou dupprimant la class d-none
	 * @param {jquery item} p_element élément concerné
	 * @param {boolean} p_visible vrai si on souhaite rendre l'item visible
	 */
	static setVisibility(p_element, p_visible) {
    if (p_visible) {
        p_element.removeClass('d-none');
    } else {
        p_element.addClass('d-none');
    }
	}

	//--------------//
	//-- CHECKERS --//
	//--------------//
	
	/**
	 * Met a jour l'apparence d'un élément si la valeur contenu dans ce dernier est considéré comme saisi ou non saisi
	 * @param {type} p_item Element dont la valeur doit être vérifiée
	 */
	static checkDataRequired(p_item) {
	// Récupérer la valeur de l'attribut data-empty-value s'il est défini
    const emptyValue = p_item.attr('data-empty-value');
		const val = p_item.val();

		// Normaliser les valeurs pour une comparaison stricte
    const normalizedVal = val !== undefined && val !== null ? String(val).trim() : '';
    const normalizedEmptyValue = emptyValue !== undefined && emptyValue !== null ? String(emptyValue).trim() : null;

    // Déterminer si la donnée est considérée comme "vide"
    const isEmpty = normalizedEmptyValue !== null 
        ? normalizedVal === normalizedEmptyValue // Comparaison stricte après normalisation
        : normalizedVal.length === 0;  // Sinon, vérifiez la longueur
		
		if (!isEmpty) {
			p_item.addClass('form-control-highlight-ok');  // Ajoute la classe de surbrillance OK
			p_item.removeClass('form-control-highlight-ko');  // Supprime la classe de surbrillance KO
		} else {
			p_item.addClass('form-control-highlight-ko');  // Ajoute la classe de surbrillance KO
			p_item.removeClass('form-control-highlight-ok');  // Supprime la classe de surbrillance OK
		}
	}
	
	//---------------------//
	//-- INPUT TYPE TEXT --//
	//---------------------//
	
	static togglePassword(p_event) {
		const $currentTarget = $(p_event.currentTarget);
    const $passwordInput = $(`#${$currentTarget.attr('data-password-input-id')}`);
    const type = $passwordInput.attr('type') === 'password' ? 'text' : 'password';
    $passwordInput.attr('type', type);
	}
	
	//-----------------------//
	//-- INPUT TYPE SELECT --//
	//-----------------------//
	
	static checkSelect(p_select) {
		if (!(p_select instanceof jQuery) || !p_select.is('select')) {
			Debug.log('p_srcSelect n\'est pas un élément <select>');
			Debug.log(p_select);
			return false;
		}
		
		return true;
	}
	
	/**
	 * Selectionne une valeur dans le select passé en paramètre et active un trigger sur change
	 * @param {type} p_selectItem
	 * @param {type} p_valToSelect
	 */
	static selectFindValue(p_selectItem, p_valToSelect) {
		p_selectItem.val(p_valToSelect);
		p_selectItem.trigger('change');
	}
	
	static selectMoveOptionsToAnotherSelect(p_srcSelect, p_destSelect) {
		 // Vérifier si les éléments sont des <select>
    if (!Dom.checkSelect(p_srcSelect) ||
			!Dom.checkSelect(p_destSelect)) {
			return;
    }
				
		// Récupérer les options sélectionnées dans le sourceSelect
		p_srcSelect.find('option:selected').each(function () {
			const $option = $(this); // Crée un objet jQuery pour l'option
			$option.prop('selected', false); // Désélectionner l'option
			p_destSelect.append($option); // Déplace l'option vers le select cible
		});
		
		Dom.selectSortSelectOptions(p_destSelect); // Appeler la fonction de tri
	}
	
	static selectMoveOptionsToMultipleSelects(p_srcSelect, p_destSelects) {
		
		// Vérifier si les éléments sont des <select>
		if (!Dom.checkSelect(p_srcSelect)) {
			return;
		}
		
		if (!Array.isArray(p_destSelects)) {
			Debug.log('p_destSelects n\'est pas un tableau');
			Debug.log(p_destSelects);
			return;
		}
		
		if (!p_destSelects.every(select => select instanceof jQuery && select.is('select'))) {
			Debug.log('Tous les éléments dans p_destSelects ne sont pas des <select>');
			Debug.log(p_destSelects);
			return;
		}

		// Récupérer les options sélectionnées dans le sourceSelect
		p_srcSelect.find('option:selected').each(function () {
			const $option = $(this); // Crée un objet jQuery pour l'option
			$option.prop('selected', false); // Désélectionner l'option
			//
			// Copier l'option dans chaque select de destination
			p_destSelects.forEach($destSelect => {
				const $clonedOption = $option.clone(); // Cloner l'option
				$destSelect.append($clonedOption); // Ajouter l'option clonée au select cible
			});

			// Supprimer l'option originale du source select
			$option.remove(); // Supprimer complètement l'option du source select
		});
		
		 // Trier les options de chaque select de destination
		p_destSelects.forEach($destSelect => {
			Dom.selectSortSelectOptions($destSelect); // Appeler la fonction de tri
		});
	}
	
	static selectRemoveOptionsFromSecondSelect(p_srcSelect, p_destSelect) {
		 // Vérifier si les éléments sont des <select>
    if (!Dom.checkSelect(p_srcSelect) ||
			!Dom.checkSelect(p_destSelect)) {
			return;
    }

		// Récupérer les valeurs des options sélectionnées dans le premier <select>
		const selectedValues = p_srcSelect.find('option:selected').map(function () {
				return $(this).val(); // Récupère la valeur de chaque option
		}).get(); // Convertir en tableau simple

		// Supprimer les options correspondantes dans le second <select>
		p_destSelect.find('option').each(function () {
				const $option = $(this);
				if (selectedValues.includes($option.val())) {
						$option.remove(); // Supprimer l'option si sa valeur est dans selectedValues
				}
		});
	}
	
	static selectSortSelectOptions($select) {
		// Récupérer toutes les options, les trier, et les réinsérer
		const $options = $select.find('option'); // Récupérer toutes les options
		$options.sort((a, b) => {
			const textA = $(a).text().toLowerCase();
			const textB = $(b).text().toLowerCase();
			return textA.localeCompare(textB); // Comparaison alphabétique
		});
		$select.empty().append($options); // Réinsérer les options triées
	}
	//---------------------------//
	//-- INPUT TYPE CHECKBOXES --//
	//---------------------------//
	
	/**
	 * 
	 * @param {type} p_selectItem id du checkbox concerné
	 * @param {boolean} p_checked true si le checkbox doit être cochée
	 * @returns {undefined}
	 */
	static setCheckBoxChecked(p_selectItem, p_checked) {
		if (p_selectItem.prop('checked') !== p_checked) {
			p_selectItem.prop('checked', p_checked).change();
		}
	}

	static setCheckBoxCheckedById(p_selectItemId, p_checked) {
		let item = $(p_selectItemId);
		Dom.setCheckBoxChecked(item, p_checked);
	}
	
	//-----------------//
	//-- FORMULAIRES --//
	//-----------------//

	/**
	 * Efface les champs du formulaires sauf ceux indiqué dans but
	 * Seul les balises suivantes sont affectées : 'select', 'input', 'textarea'
	 * @param p_form
	 * @param p_but Tableau de chaine de caractère contenant le nom des champs à ne pas réinitialiser
	 */
	static clearForm(p_form, p_but) {
		if (p_but === undefined) { 
			p_but = []; 
		} else if (!p_but.join) { p_but = [p_but]; }

		// Liste des type de champs à réinitialisé
		let lstFieldsType = ['select', 'input', 'textarea'];

		for (let i = 0; i < lstFieldsType.length; i++) {
			$(p_form).find(lstFieldsType[i]).filter(
			function (i2, o) {
				if (o.type === 'radio') {
					return false;
				}
				
				return p_but.indexOf(o.name) < 0;
			}).val('');
		}
		Dom.defaultInput($(p_form));
	}

	/**
	 * Handler utilisé par défaut lorsque la validation du formulaire n'est pas correcte
	 * @param {Event} p_event
	 * @param {type} p_validator Validateur de formulaire
	 */
	static defaultAsyncInvalidHandler(p_event, p_validator) {
		p_event.preventDefault();

		// Vérification que p_validator est un objet de validateur attendu
		if (typeof p_validator !== 'object' || 
			typeof p_validator.numberOfInvalids !== 'function' || 
			!Array.isArray(p_validator.errorList)) {
			Debug.log("p_validator n'est pas un validateur de formulaire valide.");
			return;
		}
		
		if (!p_validator.numberOfInvalids()) {
			return;
		}
		
		$('html, body').animate({
			scrollTop: $(p_validator.errorList[0].element).parent().offset().top-56 
		}, 2000);
		
		toastr.error(p_validator.errorList[0].message); 
	}

	/**
	 * Handler utilisé par défaut une fois la validation du formulaire effectuée
	 * Les inputs envoyés seront ceux du formulaire au format JSON.
	 * Le résultat sera traité comme un JSON via defaultAsyncJSONSuccessCallback et defaultAsyncJSONErrorCallback
	 * L'execution est asynchrone et emet le signal submit-error ou submit-error en cas d'erreur ou de succès
	 * @param {type} p_form formulaire deuis lequel on execute la commande
	 * @param {type} p_url si p_url est défini, alors elle sera prioritaire par rapport à l'URL du formulaire
	 */
	static defaultAsyncSubmitHandler(p_form, p_url) {
		
		// On envoi un signal qui annonce le succes du submit
		$(p_form).trigger('before-submit');
				
		let url = ((p_url === undefined) ? $(p_form).attr('action') : p_url);
		let data = $(p_form).serialize();

		$.blockUI();
		Ajax.postAsync (
			url,
			data,
			function (p_result) {
				$.unblockUI();
				Ajax.defaultAsyncJSONSuccessCallback(p_result);

				// On envoi un signal qui annonce le succes du submit
				$(p_form).trigger('submit-success', p_result);
			},
			function (p_result) {
				$.unblockUI();
				Ajax.defaultAsyncJSONErrorCallback(p_result);

				// On envoi un signal qui annonce l'erreur du submit
				$(p_form).trigger('submit-error', p_result);
			}
		);
	}
	
	/**
	 * Réinitialise les elements de formulaire contenants la donnée data-default par cette veleure
	 * @param {type} p_form
	 */
	static defaultInput(p_form) {
		p_form.find('[data-default]').each(function () {
			let tag = this.tagName.toLowerCase();
			let defaultData = $(this).attr('data-default');

			switch (tag) {
				case 'select': 
					$(this).val(defaultData);
					break;

				case 'input':				
					switch ($(this).attr('type')) {
						case 'text': 
							$(this).val(defaultData); 
							break;
							
						case '-1': 
							p_form.find('input[name="' + this.name + '"]').attr('checked', false); 
							break;
							
						default :
							p_form.find('input[name="' + this.name + '"]').attr('checked', false);
							p_form.find('input[name="' + this.name + '"][value="' + defaultData + '"]')[0].checked = true;
					}
					break;
			}
		});
	}
	
	/**
	 * Remplis les valeurs des données passées en paramètre contenu dans l'objet p_item
	 * @param {type} p_item
	 * @param {type} p_lstData données de type { clé[name]: valeur[value], clé2: valeur2 }
	 * @returns {undefined}
	 */
	static fillForm(p_item, p_lstData) {
		for (let data in p_lstData) {
			p_item.find('[name="' + data + '"]').val(p_lstData[data]);
		}
	}
	
	/**
	 * Initialise l'envoi d'un formulaire dont l'id est passé en paramètre 
	 * Recupère les donnée du formulaire et les envoi en ajax à l'action p_action
	 * @param {Form} $p_form formulaire à initialiser
	 * @param {type} p_action action Ajax a effectué
	 * @param {type} p_rules règles de validation de formulaire
	 * @param {type} p_messages message de validation de formulaire associés aux règles
	 * @param {type} p_errorPlacementIdMapping Id de l'emplacement de l'erreur. Si non défini alors l'erreur sera placée après l'élément
	 * @returns {undefined}
	 */
	static initFormValidation($p_form, p_action, p_rules, p_messages, p_errorPlacementIdMapping) {
		
		$p_form.validate({
			errorElement: 'div',
			
			errorPlacement: function(p_error, p_element) {
				let elementName = p_element.attr("name");
				if (p_errorPlacementIdMapping && p_errorPlacementIdMapping[elementName]) {
					let errorElementId = p_errorPlacementIdMapping[elementName];
					p_error.appendTo($(errorElementId));
				} else  {
					p_error.insertAfter(p_element);
				}
			},
			
			focusInvalid: false,
			
			invalidHandler: function (p_event, p_validator) {
				Dom.defaultAsyncInvalidHandler(p_event, p_validator); 
				return false;
			},
			
			submitHandler: function (p_form) {
				Dom.defaultAsyncSubmitHandler(p_form, p_action); 
				return false;
			},
			
			rules: p_rules,
			
			messages: p_messages
		});
	}

	/**
	 * Initialise l'envoi d'un formulaire dont l'id est passé en paramètre 
	 * Recupère les donnée du formulaire et les envoi en ajax à l'action p_action
	 * @param {String} p_idForm id du formulaire à initialiser
	 * @param {type} p_action action Ajax a effectué
	 * @param {type} p_rules règles de validation de formulaire
	 * @param {type} p_messages message de validation de formulaire associés aux règles
	 * @param {type} p_errorPlacementIdMapping Id de l'emplacement de l'erreur. Si non défini alors l'erreur sera placée après l'élément
	 * @returns {undefined}
	 */
	static initFormValidationById(p_idForm, p_action, p_rules, p_messages, p_errorPlacementIdMapping) {
		if (typeof p_idForm !== 'string' || !p_idForm.trim()) {
        Debug.log("Dom.initFormValidationById : le paramètre p_idForm doit être une chaîne de caractères non vide");
        return;
    }
		
		const $form = $(p_idForm);
		
		if ($form.length === 0) {
			Debug.log("Dom.initFormValidationById : le formulaire Id " + p_idForm + " n'existe pas");
		}

		Dom.initFormValidation($form, p_action, p_rules, p_messages, p_errorPlacementIdMapping);
	}

	static modifyFormRules(p_form, p_rulesObj, p_action) {
		// Initialiser le validateur si ce n'est pas déjà fait
    if (!p_form.attr('data-validator')) {
        p_form.validate();
    }
		
		// Modification des règles
		for (let item in p_rulesObj) {
			const $field = p_form.find('#' + item);
			if (p_action === 'add') {
				$field.rules('add', p_rulesObj[item]);
			} else if (p_action === 'remove') {
				$field.rules('remove');
			} else {
				Debug.log(`Action "${p_action}" non reconnue pour modifyRules`);
			}
		}
	}
	
	static replaceFormRules(p_form, p_rulesObj) {
		// Supprime toutes les règles de validation
		if (p_form.attr('data-validator')) {
			p_form.validate().destroy();
		} 
		
		Dom.modifyFormRules(p_form, p_rulesObj, 'add');
	}
}
