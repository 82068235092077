"use strict";

import { Ajax } from './ajax.js';
import { Debug } from './debug.js';
import { PFHBaseClass } from './pfhBaseClass.js';

export class ProjetSalariePageType extends PFHBaseClass {

	//------------------//
	//-- CONSTRUCTEUR --//
	//------------------//
	
	constructor() {
		super(); // Appelle le constructeur de PFHBaseClass	
		
		const mainItem = '#choix-type-demande';
		
		this.m_sel = {
			mainItem: mainItem,
			lstBtnChoixTypeDemande: `${mainItem} [name="choix-type"]`, // Objet qui recevera le cliquedu choix : contient la donnée : data-type-demande
			lstIcoChoixTypeDemande: `${mainItem} [name="ico-choix-type"]` // Icones associées à chaques choix
		};
		
		// ACTION : Clique sur le type de projet
		this.I('lstBtnChoixTypeDemande').click((p_event) => this.onBpTypeDemandeClicked(p_event));
	}
	//---------------------//
	//-- ACCESSEURS ITEM --//
	//---------------------//

	item() {
		return this.I('mainItem');
	}
	
	//------------//
	//-- EVENTS --//
	//------------//
	
		/**
	 * Met à jours le type de la demande
	 * C'est à cette étape que la demande est créée
	 * @param {Event} p_event Evenement associé
	 */
	onBpTypeDemandeClicked(p_event) {
		p_event.preventDefault();

		const $currentTarget = $(p_event.currentTarget);
		const newTypeDemande = $currentTarget.attr('data-type-demande');		

		// On vérifie les entrées
		if (!newTypeDemande) {
			Debug.log('Attribut data-type-demande manquant sur l’élément déclencheur.');
			return;
		}

		$.blockUI();
			
		Ajax.postAsync (
			'AppEmbauche/demandeMajTypeDemande', {
				typeDemande: newTypeDemande
			},

			(p_result) => {
				// On active la nouvelle séléction
				this.I('lstIcoChoixTypeDemande').removeClass('icoTypeEmployeurSelected');
				$currentTarget.find('[name="ico-choix-type"]').addClass('icoTypeEmployeurSelected');
				
				$.unblockUI();
				
				// On envoi un signal de trigger
				this.item().trigger('typeDemandeUpdated', newTypeDemande, p_result.numDemande, p_result.idDemande);
			},

			(p_result) => Ajax.defaultAsyncJSONErrorCallback(p_result, UNBLOCK_UI)
		);
	}
}

