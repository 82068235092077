/* Section pour virer les warnings de netbeans concernant les variables globales*/
/* global EMBAUCHE_MODE*/
/* global toastr */

"use strict";

import { Ajax } from './ajax.js';
import { Dom } from './dom.js';
import { Debug } from './debug.js';
import { ProjetCommon } from './projetCommon.js';

class ProjetExt extends ProjetCommon {
	
	//------------------//
	//-- CONSTRUCTEUR --//
	//------------------//
	
	constructor() {
		super(); // Appelle le constructeur de PFHBaseClass
		
		$.blockUI(); // On bloque la page pendent le chargement

		//------------------------------------------------//
		//-- Initialisation des selecteurs et des items --//
		//------------------------------------------------//

		this.setTypeDemande(TYPE_SOCIETE.EXTERNALISATION);
		
		this.m_sel = {
			...this.m_sel, // Propriétés existantes
			
			//Pagination
			btnEtape1: '.btn-menu-embauche.step-1', // Bouton de l'étape 1
			contenuEtape1: '#etape-1', // Contenu de l'étape 1
			btnEtape2: '.btn-menu-embauche.step-2', // Bouton de l'étape 2
			contenuEtape2: '#etape-2', // Contenu de l'étape 2
			btnEtape3: '.btn-menu-embauche.step-3', // Bouton de l'étape 3
			contenuEtape3: '#etape-3', // Contenu de l'étape 3
			
			// Listings
			lstBtnPagination: '.btn-menu-embauche', // Liste boutons de pagination
			lstBtnChangerEtape: `${this.sCreationProjet()} [name="changeEtape"]` // Liste boutons de changement d'étape (pas seulement la pagination)
		};
			
		//-------------//
		//-- ACTIONS --//
		//-------------//

		// CONFIG : On désactive la validation de formulaire sur un retour chariot
		this.item().on("keyup keypress", Dom.disableCarriageReturn);

		// ACTION : On clique sur un bouton de changement d'étapes
		this.I('lstBtnChangerEtape').click((p_event) => this.onChangementEtapeClicked(p_event));
	
		// ACTION : CADRE EMBAUCHE => On clique sur supprimer un contrat d'embauche
		this.iListingCadreInfoEmbauche().item().on('cadreInfoEmbaucheRemoved', (p_event) => this.onCadreInfoEmbaucheRemoved(p_event));

		// ACTION : CADRE EMBAUCHE => un contrat d'embauche à été editer
		this.item().on('contratEmbaucheEdited', (p_event) => this.onContratEmbaucheEdited(p_event));

		// ACTION : CADRE EMBAUCHE => On clique sur annuler le contrat d'embauche
		this.item().on('contratEmbaucheClosed', (p_event) => this.onContratEmbaucheClosed(p_event));

		$.unblockUI();
	}
	
	//------------------------------//
	//-- GESTION DE LA PAGINATION --//
	//------------------------------//
	
	setupPageMode(p_config) {
		// On récupère les paramètres de configuration
		const {
			currentEtape = 0,
			showBoutonsFinaliserDemande = false,
			showEditionContrat = false,
			showListingEmbauches = false
		} = p_config;

		if (currentEtape === 0) {
			Debug.log("Le choix d'une étape est obligatoire");
			return;
		}

		// Active uniquement le bouton de l'étape actuelle
		this.I('lstBtnPagination').removeClass('active'); 		
    this.I(`btnEtape${currentEtape}`).addClass('active'); 

		// Boutons de sauvegarde de la demande
		if (currentEtape === 1) {
			this.iRechercheSalarie().setResultVisibile(false);
		}
		
		// On affiche ou non le contenu de l'étape 1
		Dom.setVisibility(this.I('contenuEtape1'), currentEtape === 1);

		// On affiche ou non le contenu de l'étape 2
		Dom.setVisibility(this.I('contenuEtape2'), currentEtape === 2);

		// On affiche ou non le contenu de l'étape 3
		Dom.setVisibility(this.I('contenuEtape3'), currentEtape === 3);

		// Boutons de finalisation de la demande
		Dom.setVisibility(this.iBoutonsFinaliserDemande(), showBoutonsFinaliserDemande);

		// Edition de contrat
		Dom.setVisibility(this.iEditionContrat().item(), showEditionContrat);

		// On affiche ou non la partie de listing d'embauche
		Dom.setVisibility(this.iCadreListingEmbauches(), showListingEmbauches);
	}
	
	/**
	 * Configure la page selon un mode d'embauche
	 * @param {PAGE_MODE} p_pageMode EMBAUCHE_MODE.RECHERCHE ou EMBAUCHE_MODE.EDITION_CONTRAT
	 */
	setPageMode(p_pageMode) {
		// On récupère le bonton actuelle
		const $currentButton = $('#projet-etapes button[data-etape="' + p_pageMode + '"]');
		
		// Si ce n'est pas fait on active le bouton de l'étape actuelle
		$currentButton.attr('disabled', false);
		
		switch (parseInt(p_pageMode)) {
			case EMBAUCHE_MODE.RECHERCHE:
				this.iRechercheSalarie().clearFields();
				this.setupPageMode({
						currentEtape: 1
					});
				break;

			case EMBAUCHE_MODE.EDITION_CONTRAT:
				this.setupPageMode({
						currentEtape: 2,
						showEditionContrat: true
					});
				break;

			case EMBAUCHE_MODE.EDITION_DEMANDE:
				this.setupPageMode({
						currentEtape: 2,
						showBoutonsFinaliserDemande: true,
						showListingEmbauches: true
					});
				break;

			case EMBAUCHE_MODE.VALIDATION_DEMANDE:
				// on charge le récap de la demande avant de l'afficher
				Ajax.postAsync(
					'AppEmbauche/getTplRecapDemandeExt', {},

					(p_result) => {
						// On change d'étape
						this.I('contenuEtape3').html(p_result.html);
						this.setupPageMode({
								currentEtape: 3
							});
					},
					
					(p_result) => Ajax.defaultAsyncJSONErrorCallback(p_result)
				);

				break;
		}
	}
	
	//------------//
	//-- EVENTS --//
	//------------//
	
	/**
	 * Un cadre d'embauche à été supprimé
	 */
	onCadreInfoEmbaucheRemoved() {
		var nbEmbauches = this.iListingCadreInfoEmbauche().embaucheCount();
			
		if (nbEmbauches > 0) { 
			this.setPageMode(EMBAUCHE_MODE.EDITION_DEMANDE); 
		} else {
			this.setPageMode(EMBAUCHE_MODE.RECHERCHE); 
		}
	}
	
	onChangementEtapeClicked(p_event) {
		this.setPageMode($(p_event.currentTarget).attr('data-etape'));
	}
	
	/**
	 * On ferme l'édition de contrat en cours
	 */
	onContratEmbaucheClosed() {
		this.setPageMode(EMBAUCHE_MODE.EDITION_DEMANDE);
	}

	/**
	 * On lance l'édition d'un contrat
	 */
	onContratEmbaucheEdited() {
		this.setPageMode(EMBAUCHE_MODE.EDITION_CONTRAT);
	}
};

//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------- DOCUMENT READY -------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//

$(document).ready(function() {
	const pageCourante = Dom.getPageName();
	
	// Si on est pas dur la page d'embauche en externalisation on ne fait rien
	if (pageCourante !== "ext-declaration-demande") {
		return;
	}
	
	// Initialisation du projet
	new ProjetExt();
});
