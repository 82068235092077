"use strict";

import { Ajax } from './ajax.js';
import { Dom } from './dom.js';
import { Debug } from './debug.js';

export class ProfessionSelector {
	
	//---------------------//
	//-- DONNEES MEMBRES --//
	//---------------------//
	
	m_conventionCollective = 0;
	m_annexeConventionCollective = 0;
	
	m_sel = {
		mainItem: null
	};
	
	m_item = {
		mainItem : null
	}
	
	//------------------//
	//-- CONSTRUCTEUR --//
	//------------------//

	constructor(p_itemName) {
		// Paramètres par défaut (compatible IE)
		if (p_itemName === undefined) p_itemName="idProfession";
		
		this.m_sel.mainItem = '[name="' + p_itemName + '"]';
		
		// On configure le select2 de la profession
		this.item().select2({
			placeholder: "Sélectionnez la profession"
		});
	}

	//---------------------//
	//-- ACCESSEURS ITEM --//
	//---------------------//

	getItem(p_key, p_selector) {
		if (this.m_item[p_key] === null) {
			this.m_item[p_key] = $(p_selector);
		}
		return this.m_item[p_key];
	}
	
	item() {
		return this.getItem('mainItem', this.m_sel.mainItem);
	}

	//---------------//
	//-- ACCESSEUR --//
	//---------------//
	
	/** @returns {number} 0 ou 1 selon si l'abattement est autorisé pour cette profession */
	abattement() {		
		return this.item().find(':selected').attr('data-abattement');
	};
	
	/** @returns {boolean} vrai ou faux selon si la séléction est cadre */
	cadre() {
		return (this.item().find(':selected').attr('data-cadre') === '1');
	};
	
	/** @returns {number} la catégorie de la profession */
	categorie() {
		return this.item().find(':selected').attr('data-categorie');
	};
	
	/** @returns {number} l'ID de la séléction */
	idProfession () {
		return this.item().val();
	};
	
	/** @returns {number} 0 ou 1 selon si la séléction autorise les repet en cachets */
	repetEnCachetAllowed() {
		return this.item().find(':selected').attr('data-allow-rec') === "1";
	};
	
	//-------------//
	//-- SETTERS --//
	//-------------//
	
	/**
	 * Defini la convention collective en cours
	 * ATTENTION ASYNCHRONE
	 * @param {Number} p_conventionCollective
	 * @param {Number} p_annexeConventionCollective
	 */
	setConventionCollective = function(p_conventionCollective, p_annexeConventionCollective) {
		this.m_conventionCollective = p_conventionCollective;
		this.m_annexeConventionCollective = p_annexeConventionCollective;
		
		Ajax.postAsync(
			
			'AppProfession/loadProfessionsByConventionCollective', {					
				conventionCollective: p_conventionCollective,
				annexeConventionCollective: p_annexeConventionCollective,
				addAllowRepetEnCachet: 1
			}, 
			
			(p_result) => {
				// Supprime les éléments de la liste
				this.item().find('option').remove();
				
				// Ajoute les nouveaux elements
				p_result.lstProfessions.forEach((p_curProfession) => {
						var option = $('<option>' + p_curProfession.libelle.replace(' (SP)','') + '</option>');
						option.val(p_curProfession.id_profession);
						option.attr('data-cadre', p_curProfession.cadre);
						option.attr('data-categorie', p_curProfession.categorie);
						option.attr('data-abattement', p_curProfession.abattement);
						option.attr('data-allow-rec', p_curProfession.allowRepetEnCachet);
						
						this.item().append(option);
					});
			},
			
			(p_result) => {
				// Supprime les éléments de la liste
				this.item().find('option').remove();
				Ajax.defaultAsyncJSONErrorCallback(p_result); 
			}
		);
	};
	
	setIdProfession(p_idProfession) {
		Dom.selectFindValue(this.item(), p_idProfession);
	};
};
