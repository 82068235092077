"use strict";

import { Ajax } from './ajax.js';
import { Dom } from './dom.js';
import { Debug } from './debug.js';
import { PFHBaseClass } from './pfhBaseClass.js';

export class ProjetSalariePageDetail extends PFHBaseClass {
	
	m_fMsg = {
		DetailCommon: {
			lieu: { required: "Merci de renseigner un lieu" },
			id_pays: { required: "Merci de renseigner le pays", number: "Merci de renseigner un pays valide" },
			description: { required: "Merci de présenter le projet" },
			date_debut: { required: "Merci de renseigner la date de début" },
			date_fin: { required: "Merci de renseigner la date de fin" },
			prix_ht: { required: "Vous devez saisir un budget HT", min: "Vous devez saisir un budget HT"},
			prix_ttc: { required: "Vous devez saisir un budget TTC", min: "Vous devez saisir un budget HT" }
		}
	}
	
	m_fRules = {
		DetailCommon: {
			lieu: { required: true },
			id_pays: { required: true, number: true },
			description: { required: true },
			date_debut: { required: true },
			date_fin: { required: true }
		},
		
		// Regles spécifiques pour la validation du détail des spectacles vivants
		DetailVivant:  {
			nb_representation: { trimmedNumber: true, min: 1 },
			hotel: { number: true, min: 0 },
			repas: { number: true, min: 0 }
		}
	}
	
	m_tva = 0; // TVA actuellement utilisée
	m_baseCalculBudget = null; // Variable contenant la dernière base de calcul utilisé pour le budget (HT ou TTC)
	
	//------------------//
	//-- CONSTRUCTEUR --//
	//------------------//
	
	constructor (p_config) {
		super(); // Appelle le constructeur de PFHBaseClass	
		
		const sFormDetail = '#form-detail-projet';
		
		this.m_sel = {
			formDetail: sFormDetail, // ETAPE 3 : FORMULAIRE du détail du projet
			fdNbRep: `${sFormDetail} [name="nb_representation"]`,
			fdLieu: `${sFormDetail} [name="lieu"]`,
			fdCodePostal: `${sFormDetail} [name="code-postal"]`,
			fdVille: `${sFormDetail} [name="ville"]`,
			fdPays: `${sFormDetail} [name="id_pays"]`,
			fdPrixHT: `${sFormDetail} [name="prix_ht"]`,
			fdPrixTTC: `${sFormDetail} [name="prix_ttc"]`,
			typeBudget: `${sFormDetail} [name="type_prix_sel"]`
		};
		
		// Initialisation de l'objet
		this.setTva(p_config.tva);
		this.setTypeDemande(p_config.typeDemande);
		
		//-------------//
		//-- ACTIONS --//
		//-------------//

		// CONFIGURATION : Configuration du formulaire d'édition du détail d'une demande
		Dom.initFormValidation(
			this.I('formDetail'),
			'AppEmbauche/demandeMajDetail', 
			this.m_fRules.DetailCommon, 
			this.m_fMsgDetailCommon);
		
		// Avant le submit du détail on effectue des corrections
		this.I('formDetail').on("before-submit", () => {
			// Si le nombre de représentation est vide, on le remplis à 1 par défaut
			const $nbRepItem = this.I('fdNbRep');
			if (isNaN(parseInt($nbRepItem.val()))) { $nbRepItem.val(1); }
		});
		
		// ACTION : On change le type de calcul en cliquant sur "	Budget calculés sur des salaires NET (ou BRUT)"
		this.I('typeBudget').change((p_event) => this.onBudgetChanged(p_event));

		// ACTION : Changement du montant HT ou TTC du détail
		this.I('fdPrixHT').change((p_event) => this.onBudgetChanged(p_event));
		this.I('fdPrixTTC').change((p_event) => this.onBudgetChanged(p_event));
		
		// On execute la fonction
		this.onBudgetChanged();
	}
	
	//---------------------//
	//-- ACCESSEURS ITEM --//
	//---------------------//
	
	iFormDetail() {
		return this.I('formDetail');
	}
	
	//----------------//
	//-- ACCESSEURS --//
	//----------------//
	
	prixHt() {
		return Number.parseFloat(this.I('fdPrixHT').val());;
	}
	
	prixTtc() {
		return Number.parseFloat(this.I('fdPrixTTC').val());;
	}
	
	//-------------//
	//-- SETTERS --//
	//-------------//
	
	setTva(p_tva) {
		this.m_tva = p_tva;
	}
	
	//----------------//
	//-- EVENEMENTS --//
	//----------------//
	
	onBudgetChanged() {
		const $typeBudget = this.I('typeBudget');
		const $prixHT = this.I('fdPrixHT');
		const $prixTTC = this.I('fdPrixTTC');

		const budgetNetouBrut = $typeBudget.is(':checked');

		$prixHT.prop('disabled', budgetNetouBrut); // On active ou desactive le HT
		$prixTTC.prop('disabled', budgetNetouBrut); // On active ou desactive le TTC
		
		if (budgetNetouBrut) { // Si le budget est calculé sur le salaire NET ou BRUT	
			
			$prixHT.val(0); // On remet le HT à 0
			$prixTTC.val(0); // On remet le TTC à 0

			// On supprime une regle de saisie pour le HT
			$prixHT.rules('remove');
			this.iFormDetail().validate().element('[name="prix_ht"]');
			
			// On supprime une regle de saisie pour le TTC
			$prixTTC.rules('remove');
			this.iFormDetail().validate().element('[name="prix_ttc"]');
		} else { // Si le budget est calculé sur le HT ou TTC
			
			// On ajoute une regle de saisie pour le HT
			this.iFormDetail().validate().element('[name="prix_ht"]');
			$prixHT.rules('add', {
				min:1, required: true, number: true,
				messages: { required: "Merci de saisir un budget valide", number: "nombre seulement", min:"Merci d'indiquer un prix" }					
			});
			
			// On ajoute une regle de saisie pour le TTC
			this.iFormDetail().validate().element('[name="prix_ttc"]');
			$prixTTC.rules('add', {
				min:1, required: true, number: true,
				messages: { required: "Merci de saisir un budget valide", number: "nombre seulement", min:"Merci de d'indiquer un prix" }					
			});
			
			let ht = this.prixHt();
			let ttc = this.prixTtc();

			var name = (this.name === 'tva' ? this.m_baseCalculBudget : this.name);		
			this.m_baseCalculBudget = name;

			if (name === 'prix_ttc' && !isNaN(ttc)) {
				ht = ttc / (1 + this.m_tva / 100);
				$prixHT.val(ht.toFixed(3));
				this.iFormDetail().validate().element('[name="prix_ht"]');
			} 
			else if (!isNaN(ht)) {
				ttc = ht * (1 + this.m_tva / 100);
				$prixTTC.val(ttc.toFixed(3));
				this.iFormDetail().validate().element('[name="prix_ttc"]');
			}
		}
	}
	
	//------------------------//
	//-- FONCTIONS INTERNES --//
	//------------------------//
	
	/**
	 * Modifie les règles de validation de formulaire du détail en fonction du type de demande
	 * @param {TYPE_SOCIETE} p_typeDemande type de la demande
	 */
	setTypeDemande(p_typeDemande) {
		if (p_typeDemande === TYPE_SOCIETE.PROD_FH) {
			Dom.modifyFormRules(this.I('formDetail'), this.m_fRules.DetailVivant, 'add');
		} else {
			Dom.modifyFormRules(this.I('formDetail'), this.m_fRules.DetailVivant, 'remove');
		}
	}
}
