/* Section pour virer les warnings de netbeans concernant les variables globales*/
/* global toastr */

"use strict";

import { Ajax } from './ajax.js';
import { Debug } from './debug.js';
import { Dom } from './dom.js';
import { Calendar } from './itemCalendar.js';
import { ProfessionSelector } from './itemProfessionSelector.js';
import { PFHBaseClass } from './pfhBaseClass.js';

export class EditionContrat extends PFHBaseClass {
	
	//---------------------//
	//-- DONNEES MEMBRES --//
	//---------------------//
	
	m_typeDemande = TYPE_SOCIETE.NON_DEFINI;
	m_defaultLieu = '';
	m_defaultCodePostal = '';
	m_defaultVille = '';
	m_defaultPays = '';
	
	m_flag = {
		loadingContrat: false, // Etat du chargement du contrat
		saveContratProfessionEnabled: true, // Défini si on souhaite ou non activer la sauvegarde automatique des professions
		saveContratLieuEnabled: true, // Défini si on souhaite ou non activer la sauvegarde automatique des lieux
		saveContratSalaireEnabled: true // Défini si on souhaite ou non activer la sauvegarde automatique du salaire
	};
	
	//------------------//
	//-- CONSTRUCTEUR --//
	//------------------//
		
	constructor() {
		super(); // Appelle le constructeur de PFHBaseClass
		
		const formEditionEmbauche = '#formEditionEmbauche';
		
		
		this.m_item = {
			calendar: new Calendar(this.m_sel.creationProjet),
			profSelector: new ProfessionSelector()
		};
		
		this.m_sel = {
			mainItem: `${formEditionEmbauche} [name="declaration-contrat-edition"]`, // Zone d'édition d'un contrat
			formEditionEmbauche: formEditionEmbauche, // FORMULAIRE d'édition d'une embauche
			btnFermerContrat: `${formEditionEmbauche} [name="fermer-contrat"]`, // Bouton de fermeture d'un contrat
			
			// Profession
			selectedProfessionCategorie: `${formEditionEmbauche} [name="profession-categorie"]`,
			selectedProfessionCadre: `${formEditionEmbauche} [name="profession-cadre"]`,
			selectedProfessionNonCadre: `${formEditionEmbauche} [name="profession-non-cadre"]`,
			selectedProfessionAbattement: `${formEditionEmbauche} [name="profession-abattements"]`,

			// Lieu
			lieu: `${formEditionEmbauche} [name="lieu-embauche"]`,
			lieuCodePostal: `${formEditionEmbauche} [name="code-postal-lieu"]`,
			lieuDifferent: `${formEditionEmbauche} [name="lieu-different"]`,
			lieuVille: `${formEditionEmbauche} [name="ville-lieu"]`,

			// Frais
			fraisAjouter: '#contrat-ajouter-frais',
			fraisBpAjoutMontantFraisAuSalaire: `${formEditionEmbauche} [name="ajout-montant-frais-au-salaire"]`,
			fraisBpSupprimer: `${formEditionEmbauche} [name="declaration-supprimer-frais"]`,
			fraisListing: '#contrat-listing-frais',
			fraisMontant: '#contrat-montant-frais',
			fraisQuantite: '#contrat-quantite-frais',
			fraisType: '#contrat-type-frais',
			fraisKmsCalculer: '#frais-kms-calculer',

			// Salaire
			salaire: '#contrat-salaire', // Montant du salaire
			salaireType: `${formEditionEmbauche} [name="salaire-type"]`,
			salaireTypeBrut: '#contrat-salaire-type-0',
			salaireTypeNet: '#contrat-salaire-type-1',
			salaireTypeCE: '#contrat-salaire-type-2',
			salaireTypeHT: '#contrat-salaire-type-3',
			salaireTypeTTC: '#contrat-salaire-type-4'
		};
		
		//-------------------------------//
		//-- Configuration des Actions --//
		//-------------------------------//
	
		// CONFIG : On désactive la validation de formulaire sur un retour chariot
		this.I('formEditionEmbauche').on("keyup keypress", Dom.disableCarriageReturn);

		// FRAIS : On clique sur ajouter un frais
		this.I('fraisAjouter').click((p_event) => this.onFraisAjouterClicked(p_event));

		// FRAIS : On selectionne un nouveau type de frais dans la déclaration
		this.I('fraisType').change((p_event) => this.onFraisMajTypeFrais(p_event));

		// FRAIS : On clique sur le calcul du frais kilometriques
		this.I('fraisKmsCalculer').click((p_event) => this.onEmbaucheCalculFraisKmsClicked(p_event));

		// LIEU : Modification du lieu
		this.I('lieu').change((p_event) => this.onLieuDifferentChanged(p_event));
		
		// LIEU : Modification du code postal (OPTIONNEL)
		this.I('lieuCodePostal', false).change((p_event) => this.onLieuDifferentChanged(p_event));

		// LIEU : Clique sur lieu différent (OPTIONNEL)
		this.I('lieuDifferent', false).change((p_event) => this.onLieuDifferentChanged(p_event));
			
		// LIEU : Modification de la ville (OPTIONNEL)
		this.I('lieuVille', false).change((p_event) => this.onLieuDifferentChanged(p_event));
		
		// SALAIRE : Modification du salaire
		this.I('salaire').change((p_event) => this.onSalaireChanged(p_event));
		
		// SALAIRE : Modification du type de salaire
		this.I('salaireType').change((p_event) => this.onSalaireChanged(p_event));
		
		// PROFESSION : Modification de la profession
		this.iProfSelector().item().change((p_event) => this.onProfessionChanged(p_event));
		
		// GLOBAL : Gestion des click sur les éléments dynamique
		$(document).on('click', '[name="declaration-supprimer-frais"], [name="ajout-montant-frais-au-salaire"]', (p_event) => this.onDocumentElementClicked(p_event));
	}
		
	//---------------------//
	//-- ACCESSEURS ITEM --//
	//---------------------//

	item() { return this.I('mainItem'); }
	
	iCalendar() { return this.m_item.calendar; }
	
	iProfSelector() { return this.m_item.profSelector; }
	
	//----------------//
	//-- ACCESSEURS --//
	//----------------//

	/**
	 * @returns le code postal du contrat
	 */
	codePostal() {	
		// En externalisation il n'y à pas de code postal
		if (this.typeDemande() === TYPE_SOCIETE.EXTERNALISATION) {
			return '';
		}
			
		// En priorité, on retourne la valeur défini dans le contrat si lieu différent est coché
		if (this.lieuDifferent()) {
			return this.I('lieuCodePostal').val();
		}

		// Sinon on retourne la valeur par défaut
		return this.m_defaultCodePostal;
	}
		
	destinationCalculFrais() {
		return [this.lieu(), this.codePostal(), this.ville(), this.pays()].filter(Boolean).join(',');
	}
	
	fraisMontant() {
		var montant = this.I('fraisMontant').val().replace(",", ".");
		return Number.parseFloat(montant); 
	}
	
	fraisQuantite() {
		return this.I('fraisQuantite').val(); 
	}
	
	fraisType() {
		return this.I('fraisType').val(); 
	}
	
	/**
	 * @returns le lieu du contrat
	 */
	lieu() {
		// En externalisation ou bien si la case lieu différent est cochée, on retourne la valeur contenu dans le lieu
		if (this.typeDemande() === TYPE_SOCIETE.EXTERNALISATION || this.lieuDifferent()) {
			return this.I('lieu').val();
		}
		
		// Sinon on retourne la valeur par défaut
		return this.m_defaultLieu;
	}
	
	/**
	 * Retourne vrai si le lieu du contrat en cours est différent de la demande
	 * @returns {Boolean}
	 */
	lieuDifferent() {		
		if (this.typeDemande() === TYPE_SOCIETE.EXTERNALISATION) {
			return true;
		}
		
		return this.I('lieuDifferent').is(':checked');
	}
	
	pays () {
		return this.m_defaultPays;
	}
	
	salaireMontant() {
		const salaire = this.I('salaire').val();
		if (salaire === '') return Number.parseFloat(0);
		return Number.parseFloat(salaire);		
	}
	
	salaireBrut() {		
		if (this.I('salaireTypeBrut').prop("checked") === true) {
			return this.salaireMontant();
		}
		
		return 0;
	}
	
	salaireCE() {
		if (this.salaireCEChecked()) {
			return this.salaireMontant();
		}
		
		return 0;
	}
	
	salaireCEChecked() {		
		return this.I('salaireTypeCE').prop("checked") ;
	}
	
	salaireHT() {		
		if (this.salaireHTChecked()) {
			return this.salaireMontant();
		}
		
		return 0;
	}
	
	salaireHTChecked() {
		return this.I('salaireTypeHT').prop("checked");
	}
	
	salaireNet() {		
		if (this.salaireNetChecked()) {
			return this.salaireMontant();
		}
		
		return 0;
	}
	
	salaireNetChecked() {
		return this.I('salaireTypeNet').prop("checked");
	}
	
	salaireTTC() {		
		if (this.salaireTTCChecked()) {
			return this.salaireMontant();
		}
		
		return 0;
	}
	
	salaireTTCChecked() {
		return this.I('salaireTypeTTC').prop("checked");
	}
	
	salaireType() {
		let salaireType = 1; // Net
		
		if (this.I('salaireTypeBrut').is(':checked')) { salaireType = 0; } // Salaire Brut
		if (this.I('salaireTypeCE').is(':checked')) { salaireType = 2; } // Coût Employeur
		if (this.salaireHTChecked()) { salaireType = 3; } // H.T
		if (this.I('salaireTypeTTC').is(':checked')) { salaireType = 4; } // TTC
		
		return salaireType;
	}
	
	typeDemande() {
		return this.m_typeDemande;
	}
	
	/**
	 * @returns la ville du contrat
	 */
	ville() {
		switch (this.typeDemande()) {
			// Si on est pas en externalisation de paie, on à une ville
			case TYPE_SOCIETE.PROD_FH :
			case TYPE_SOCIETE.AUDIOVISUAL : {
				if (this.lieuDifferent()) {
					return this.I('lieuVille').val();
				}	else {
					return this.m_defaultVille;
				} 
				break;
			}
			
			case TYPE_SOCIETE.EXTERNALISATION : {
					return '';
			}
		}
	}
	
	//-------------//
	//-- SETTERS --//
	//-------------//

	/**
	 * @param {String} p_codePostal code postal du lieu du contrat à définir
	 */
	setCodePostal(p_codePostal) {
		this.I('lieuCodePostal').val(p_codePostal); 
	}
	
	setConventionCollective = function(p_conventionCollective, p_annexeConventionCollective) {
		this.iProfSelector().setConventionCollective(p_conventionCollective, p_annexeConventionCollective);
	}
	
	setDefaultLieu(p_defaultLieu) {
		this.m_defaultLieu = p_defaultLieu;
	}
	
	setDefaultCodePostal(p_defaultCodePostal) {
		this.m_defaultCodePostal = p_defaultCodePostal;
	}
	
	setDefaultVille(p_defaultVille) {
		this.m_defaultVille = p_defaultVille;
	}
	
	setDefaultPays(p_defaultPays) {
		this.m_defaultPays = p_defaultPays;
	}
	
	/**
	 * @param {Number} p_montant montant du frais à définir
	 */
	setFraisMontant(p_montant) {
		this.I('fraisMontant').val(p_montant); 
	}

	setFraisQuantite(p_quantite) {
		this.I('fraisQuantite').val(p_quantite); 
	}

	setFraisType(p_type) {
		Dom.selectFindValue(this.I('fraisType'), p_type);
	}

	/**
	 * @param {String} p_lieuContrat lieu du contrat à définir
	 */
	setLieu(p_lieuContrat) {
		this.I('lieu').val(p_lieuContrat); 
	}
	
	/**
	 * @param {int} p_lieuDifferent 0 ou 1
	 */
	setLieuDifferent(p_lieuDifferent) {
		Dom.setCheckBoxCheckedById(' [name="lieu_different"]', parseInt(p_lieuDifferent)===1); 
	}
	
	/**
	 * Remplis le listing de frais avec le contenu HTML passé en paramètres
	 * @param {String} p_html
	 */
	setListingFrais(p_html) {
		$(this.m_sel.fraisListing).html(p_html);
		
		this.updateFraisActionsVisibility();
	}
	
	/**
	 * Remplis es salaire en fonction et coche les bonnes cases en fonction des paramètres
	 * @param {type} p_brut
	 * @param {type} p_net
	 * @param {type} p_ce
	 * @param {type} p_ht
	 * @param {type} p_ttc
	 */
	setSalaire(p_brut, p_net, p_ce, p_ht, p_ttc) {
		
		const valeurs = [p_brut, p_net, p_ce, p_ht, p_ttc];
		const elements = [
			this.m_sel.salaireTypeBrut,
			this.m_sel.salaireTypeNet,
			this.m_sel.salaireTypeCE,
			this.m_sel.salaireTypeHT,
			this.m_sel.salaireTypeTTC
		];

		// Désactive tout d'abord tous les types de salaire
		$(this.m_sel.salaireType).prop("checked", false);

		for (let i = 0; i < valeurs.length; i++) {
			if (parseFloat(valeurs[i]) !== 0.00) {
				// Définit la valeur du salaire
				this.setSalaireMontant(valeurs[i]);
				// Active le type de salaire correspondant
				$(elements[i]).prop("checked", true);
				break;
			}
		}

		// Déclenche le changement après la mise à jour
		this.I('salaireType').trigger('change');
	}
	
	setSalaireMontant(p_montant) {
		this.I('salaire').val(p_montant);
	}
	
	setTypeDemande(p_typeDemande) {
		this.m_typeDemande = p_typeDemande;
	}
	
	/**
	 * @param {String} p_ville fille du lieu du contrat à définir
	 */
	setVille(p_ville) {
		this.I('lieuVille').val(p_ville); 
	}
	
	//------------//
	//-- EVENTS --//
	//------------//
	
	/**
	 * Ajoute le montant du frais défini par this au frais du contrat (si HT ou TTC)
	 * @param {Event} p_event Evenement associé
	 * @param {JQuery Item} p_item associé
	 */
	onAjoutMontantFraisAuSalaireClicked(p_event, p_item) {
		p_event.preventDefault();
		
		var splittedData = p_item.attr('data-frais').split('_');
		
		// On ajoute le frais		
		Ajax.postAsync(
			'AppEmbauche/contratAjouterMontantFrais', {
				typeFrais: splittedData[0],
				quantite: splittedData[1],
				montant: Number.parseFloat(splittedData[2]).toFixed(2)
			}, 
			
			(p_result) => {
				// Si un HT ou TTC à été modifié, alors on met a jours le salaires
				if (this.salaireTTCChecked()) {
					this.setSalaireMontant(p_result.ttc);
				} else if (this.salaireHTChecked()) {
					this.setSalaireMontant(p_result.ht);
				}
				
				this.clearFraisInput();
			},
			
			(p_result) => Ajax.defaultAsyncJSONErrorCallback(p_result)
		);
		
		return false; // Annule l'action par défaut et stoppe la propagation
	}
	
		/**
	 * @param {Event} p_event Evenement associé
	 * @returns {undefined}
	 */
	onEmbaucheCalculFraisKmsClicked(p_event) {
		$.blockUI();
		
		p_event.preventDefault();
		var erreur = "";
		
		var destination = this.destinationCalculFrais();
		if (destination === '') { 
			var typeDemande = this.typeDemande();
		
			switch (typeDemande) {
				case TYPE_SOCIETE.PROD_FH :
				case TYPE_SOCIETE.AUDIOVISUAL :
					erreur = "Vous n'avez pas rempli le champ Ville dans le lieu de la prestation - étape 2 - Détails"; 
					break;

				case TYPE_SOCIETE.EXTERNALISATION : 
					erreur = "Vous n'avez pas rempli le champ Lieu de la prestation"; 
					break;
			}	
		}
		
		// En cas d'erreur on s'arrete ici
		if (erreur !== "") { 
			$.unblockUI();
			toastr.error(erreur);
			return false; 
		} else {
			
			Ajax.postAsync (
				
				'AppEmbauche/contratCalculFraisKms', {
					'destination' : destination
				}, 
				
				(p_result) => {
					$.unblockUI();
					this.setFraisMontant(p_result.frais);
				},
				
				(p_result) => {
					$.unblockUI();

					if (p_result.codeErreur === 1) {
						// On demande les informations manqantes pour le véhicule
						this.openVehiculeInfos(p_result.vehicule, p_result.puissanceFiscale);
						return;
					}
					
					Ajax.defaultAsyncJSONErrorCallback(p_result);
				}
			);
		}
	}
	
	/**
	 * @param {Event} p_event Evenement associé
	 */
	onLieuDifferentChanged(p_event) {
		p_event.preventDefault();
		
		// On switch la case lieu différent
		var item = $(this.m_sel.lieuDifferent);		
		if (item.prop('checked')) { 
			$('#declaration-lieu-diff').removeClass('d-none'); 
		} else { 
			$('#declaration-lieu-diff').addClass('d-none'); 
		}
		
		this.saveContratLieu();
	}
	
	onProfessionChanged() {
		// On commence par regarder si on à modifier la catégorie salariale
		var categorieUpdated = (this.I('selectedProfessionCategorie').val() !== this.iProfSelector().categorie());
		
		// On coche ou décoche le checkbox cadre
		const cadre = this.iProfSelector().cadre();
		
		Dom.setVisibility(this.I('selectedProfessionCadre'), cadre); 
		Dom.setVisibility(this.I('selectedProfessionNonCadre'), !cadre); 
		
		// On définie l'abattement
		$(this.m_sel.selectedProfessionAbattement).val(this.iProfSelector().abattement()); 
		
		// On définie la catégorie
		this.I('selectedProfessionCategorie').val(this.iProfSelector().categorie()); 
		
		// On définie la répétition en cachet
		var repetEnCachetItem = $(this.m_sel.formEditionEmbauche + ' [name="cachetDeRepet"]');		
			
		if (this.iProfSelector().repetEnCachetAllowed()) {
			repetEnCachetItem.prop('disabled', false);
		} else {
			repetEnCachetItem.prop('disabled', true);
			repetEnCachetItem.val(0);
			repetEnCachetItem.trigger('change');
		}
		
		// Si on est en train de charger le contrat ou que l'on désactive la sauvegarde, alors on ne met rien à jours
		if (this.m_flag.loadingContrat === false && this.m_flag.saveContratProfessionEnabled === true) {
			// On sauvegarde la profession du contrat
			this.saveContratProfession(() => { // ATTENTION ASYNCHRONE
				// Si la catégorie salariale à été modifiee, alors on supprime toutes les dates
				if (categorieUpdated) { 
					this.m_item.calendar.removeAllDates(); } // ATTENTION ASYNCHRONE
			});
		}
	}

	/**
	 * Gestion des click sur les éléments dynamique
	 * @param {Event} p_event
	 */
	onDocumentElementClicked(p_event) {
			const $item = $(p_event.currentTarget); // Item cliqué
			const name = $item.attr('name'); // Nom de l'item cliqué

			switch (name) {
				case 'declaration-supprimer-frais' :
					this.onSupprimerFraisClicked(p_event);
					break;
				case 'ajout-montant-frais-au-salaire' :
					this.onAjoutMontantFraisAuSalaireClicked(p_event, $item);
					break;
			}
		}
		
	onFraisAjouterClicked(p_event) {
		p_event.preventDefault();
			
		// On récupère les valeures
		const typeFrais = this.fraisType();
		const montant = this.fraisMontant();
		const quantite = this.fraisQuantite();
		
		// Gestion des erreures
		var erreur = "";
		if (typeFrais === 0) {
			erreur = "Vous devez séléctionner un type de frais"; 
		}
		if (montant === 0) {
			erreur = "Vous devez indiquer un montant pour le frais"; 
		}
		if (quantite === "") {
			erreur = "Vous devez indiquer une quantité pour le frais";
		}
		if (this.iCalendar().nbSelectedDates() === 0) {
			erreur = "Vous devez séléctionner au moins une date d'embauche"; 
		}
		
		// En cas d'erreur on s'arrete ici
		if (erreur !== "") { toastr.error(erreur); return false; } 
		
		$.blockUI();
		
		// On ajoute le frais		
		Ajax.postAsync (
			'AppEmbauche/contratAjouteFrais', {
				typeFrais: typeFrais,
				quantite: quantite,
				montant: montant.toFixed(2)
			}, 
			
			(p_result) => {
				Debug.log("Result Ext:");
				Debug.log(p_result);
					
				// on rafraichis le listring de frais
				this.refreshEmbaucheListingFrais(() => {
				
					Debug.log("Result :");
					Debug.log(p_result);
					// Si un HT ou TTC à été modifié, alors on met a jours le salaires
					if (this.salaireTTCChecked()) {
						this.setSalaireMontant(p_result.ttc);
					} else if (this.salaireHTChecked()) {
						this.setSalaireMontant.val(p_result.ht);
					}				

					this.clearFraisInput();

					$.unblockUI();
				});
			},
			
			(p_result) => {
				$.unblockUI();
				Ajax.defaultAsyncJSONErrorCallback(p_result);
			}
		);
	}

	/**
	 * Mises a jours du visuel de type de frais de l'embauche
	 * Dans le cas d'un frais kilometrique on ajoute un bouton de calcul de frais
	 */
	onFraisMajTypeFrais() {
		let $fraisType = this.I('fraisType');
		let $montant = this.I('fraisMontant');
		
		// On regarde si le frais séléctionné possède un forfait fixe
		var forfait = $fraisType.find(':selected').attr('data-somme');
		if (Number.parseFloat(forfait) > 0.00) {
			$montant.val(forfait);
			$montant.prop('disabled', true);
		} else {
			$montant.val(0.00);
			$montant.prop('disabled', false);
		}

		// On affiche ou non l'icone des frais kilométriques
		Dom.setVisibility(this.I('fraisKmsCalculer'), $fraisType.val() === 'KMS');
	}

	onSalaireChanged() {
		// La modification du salaire (ou type de salaire implique une mise à jours de la visibilité)
		this.updateFraisActionsVisibility();
		
		// Sauvegarde du salaire et du type de salaire
		this.saveContratSalaire();
	}
	
	onSupprimerFraisClicked(p_event) {
		p_event.preventDefault();
		
		var splittedData = $(p_event.currentTarget).attr('data-frais').split('_');
		
		Ajax.postAsync (
			'AppEmbauche/contratSupprimeFrais', {
				typeFrais: splittedData[0],
				quantite: splittedData[1],
				montant: Number.parseFloat(splittedData[2]).toFixed(2)
			},
			
			() => {
				this.refreshEmbaucheListingFrais();
			}, 
			
			(p_result) => Ajax.defaultAsyncJSONErrorCallback(p_result)
		);
		
		return false; // Annule l'action par défaut et stoppe la propagation
	}
	
	//-----------------//
	//-- SAUVEGARDES --//
	//-----------------//
	
	/**
	 * ATTENTION ASYNCHRONE
	 */
	saveContratLieu() {
		if (this.m_flag.loadingContrat === true) {
			return;
		}
		
		if (this.m_flag.saveContratLieuEnabled === false) {
			return;
		}
		
		Ajax.postAsync(
			'AppEmbauche/contratMajLieu', {
				lieuDifferent: (this.lieuDifferent() === true ? '1' : '0'),
				lieu: this.lieu(),
				codePostal: this.codePostal(),
				ville: this.ville()
			},
			
			(p_result) => {
				this.m_flag.saveContratLieuEnabled = false;
				
				this.setLieu(p_result.contrat.lieu);
				this.setCodePostal(p_result.contrat.code_postal);
				this.setVille(p_result.contrat.ville);
				
				this.m_flag.saveContratLieuEnabled = true;
				Ajax.defaultAsyncJSONSuccessCallback(p_result);
			},
			
			(p_result) => Ajax.defaultAsyncJSONErrorCallback(p_result)
		);
	}
	
	/**
	 * ATTENTION ASYNCHRONE
	 * @param {function} p_successCallBack si défini ce callback est appelé en cas de succès
	 */
	saveContratProfession(p_successCallBack=null) {
		if (this.m_flag.loadingContrat === true) {
			return;
		}

		if (this.m_flag.saveContratProfessionEnabled === false) {
			return;
		}

		// On sauvegarde la profession dans le contrat	
		Ajax.postAsync (
			'AppEmbauche/contratMajProfession', {
				idProfession: this.iProfSelector().idProfession()
			},
			
			(p_result) => {
				Ajax.defaultAsyncJSONSuccessCallback(p_result);
				
				if (p_successCallBack) {
					p_successCallBack(p_result);
				}
			},
			
			(p_result) => Ajax.defaultAsyncJSONErrorCallback(p_result)
		);
	}
	
	/**
	 * ATTENTION ASYNCHRONE
	 */
	saveContratSalaire() {
		if (this.m_flag.loadingContrat === true) {
			return;
		}
		
		if (this.m_flag.saveContratSalaireEnabled === false) {
			return;
		}

		// On sauvegarde la profession dans le contrat	
		Ajax.postAsync (
			'AppEmbauche/contratMajSalaire', {
				typeSalaire: this.salaireType(),
				montant: this.salaireMontant()
			},
			
			(p_result) => Ajax.defaultAsyncJSONSuccessCallback(p_result),
			
			(p_result) => Ajax.defaultAsyncJSONErrorCallback(p_result)
		);
	}
	
	//------------//
	//-- DIVERS --//
	//------------//
	
	/**
	 * Réinitialise la saisie des frais
	 */
	clearFraisInput() {
		this.setFraisQuantite(1);
		this.setFraisType('0');
		this.setFraisMontant(0);
	}
		
	loadContrat(p_data) {
		this.m_flag.loadingContrat = true;
		
		// DIVERS
		$(this.m_sel.formEditionEmbauche + ' .btn-cancel').attr("data-id-contrat", p_data['idContrat']); // Bouton annuler
		
		// NOM DU SALARIE
		var name = p_data['nom'] + ' ' + p_data['prenom'];
		$('.select-salarie-name').text(name);
		
		// CALENDRIER
		this.m_item.calendar.setCalendarHtmlContent(p_data['resultCalendrierHTML']);

		// REPET EN CACHET (avant la profession)
		var repetEnCachetItem = $('#table-salaries-dates-prestations [name="cachetDeRepet"]');
		repetEnCachetItem.val(p_data['repetEnCachet']);
		
		// PROFESSION
		this.iProfSelector().setIdProfession(p_data['idProfession']);

		if (p_data['professionCadre'] !== 0) {
				$('.bi-check-square').show();
				$('.bi-square').hide();
		} else {
				$('.bi-check-square').hide();
				$('.bi-square').show();
		}

		// FRAIS (avant les salaires)
		this.setListingFrais(p_data['fraisHTML']);
		this.clearFraisInput();

		// SALAIRES
		this.setSalaire(p_data['brutDefaut'], p_data['netDefaut'], p_data['ceDefaut'], p_data['htDefaut'], 0);

		// LIEU
		this.setLieuDifferent(p_data['lieuDifferent']);
		this.setLieu(p_data['lieu']);
		this.setCodePostal(p_data['codePostal']);
		this.setVille(p_data['ville']);
		
		if (this.typeDemande() !== TYPE_SOCIETE.EXTERNALISATION) {
			// Gestion de l'abattement
			// @TODO A REVOIR C'EST BIEN MOCHE
			if (p_data['abattement'] !== "") {
				var ladate = new Date();

				// On parse l'abattement
				var abattements = p_data['abattement'].split(";").map(function (item) {
						var values = item.split(":");
						return {year: values[0], value: +values[1] };
				});

				var lastAbattement = abattements.reduce(function (a, b) {
						return +a.year > +b.year ? a : b;
				});

				// Si on est un artiste
				if (this.iProfSelector().categorie() === CATEGORIE_SALARIALE.ARTISTE) {
					var data = -1;
					if (lastAbattement.year < ladate.getFullYear() && lastAbattement.value === 1) {
						alert("Le salarié n'a pas défini l'abattement pour l'année en cours. En " + lastAbattement.year + ", le salarié l'avait accepté, donc il sera appliqué");
						data = 1;
					}

					if (lastAbattement.year < ladate.getFullYear() && lastAbattement.value === 0) {
						alert("Le salarié n'a pas défini l'abattement pour l'année en cours. En " + lastAbattement.year + ", le salarié l'avait refusé, donc il ne sera pas appliqué");
						data = 0;
					}

					if (lastAbattement.year === ladate.getFullYear() && lastAbattement.value === -1) {
						alert("Le salarié n'a pas défini l'abattement pour l'année en cours, ni les années précédentes. Il sera appliqué");
						data = 1;
					}

					if (data >= 0) {	
						// ATTENTION ASYNCHRONE
						$.blockUI();

						Ajax.postAsync(

							'AppSalarie/changerAbattement', {
								id_salarie_client: p_data['idSalarieClient'], 
								abattements: p_data['abattement'].replace(":", "+"), 
								annee: ladate.getFullYear(), 
								valeur: data
							},

							(p_result) => {
								Ajax.defaultAsyncJSONSuccessCallback(p_result);
								$.unblockUI();
							},

							(p_result) => {
								$.unblockUI();
								Ajax.defaultAsyncJSONErrorCallback(p_result);
							}
						);
					}
				}
			}
		}
		
		this.item().trigger('contratEmbaucheEdited', p_data);
		this.m_flag.loadingContrat = false;
	}
	
	/**
	 * ATTENTION ASYNCHRONE
	 * @param {integer} p_idContrat id du contrat à éditer
	 */
	loadContratByIdContrat(p_idContrat) {
		
		// On charge les données du contrat
		Ajax.postAsync(
			'AppEmbauche/contratLoadAndEdit', {
				idContrat: p_idContrat
			},
			
			(p_result) => {
				this.loadContrat({
						'fraisHTML' : p_result.html.frais,
						'nom' : p_result.salarie.nom,
						'prenom' : p_result.salarie.prenom,
						'idProfession' : p_result.contrat.id_profession,
						'professionCadre' : p_result.contrat.cadre,
						'abattement' : p_result.salarie.abattements,
						'brutDefaut' : parseFloat(p_result.contrat.brut),
						'netDefaut' : parseFloat(p_result.contrat.net),
						'ceDefaut' : parseFloat(p_result.contrat.cout_employeur), 
						'htDefaut' : parseFloat(p_result.contrat.ht), 
						'lieuDifferent' : p_result.contrat.lieu_different, 
						'lieu' : p_result.contrat.lieu, 
						'codePostal' : p_result.contrat.code_postal, 
						'ville' : p_result.contrat.ville, 
						'repetEnCachet' : p_result.contrat.repet_en_cachet,
						'resultCalendrierHTML' : p_result.html.dates
					});
			},
			
			(p_result) => Ajax.defaultAsyncJSONErrorCallback(p_result)
		);
	}
	
	openVehiculeInfos(p_vehicule, p_puissanceFiscale) {
		$('#vehicule-info-model').val(p_vehicule);
		$('#vehicule-info-pf').val(p_puissanceFiscale);
		$('#vehicule-info').modal();
	}

	/**
	 * ATTENTION ASYNCHRONE
	 */
	refreshEmbaucheListingFrais(p_callBackOnSuccess=null) {
		Ajax.postAsync (
			'AppEmbauche/contratGetHtmlListingFrais', {},

			(p_result) => {
				this.setListingFrais(p_result.html.frais);				
				Ajax.defaultAsyncJSONSuccessCallback(p_result);
				
				if (p_callBackOnSuccess) {					
					p_callBackOnSuccess(p_result);
				}
			},

			(p_result) => Ajax.defaultAsyncJSONErrorCallback(p_result)
		);
	}
	
	/**
	 * Met a jours la visibilité des actions associés aux frais en fonction 
	 * du type de salaire séléctionné
	 */
	updateFraisActionsVisibility() {
		// On modifie l'affichage du frais
		if ($(this.m_sel.salaireTypeBrut).prop("checked")) {
			$(this.m_sel.fraisBpAjoutMontantFraisAuSalaire).addClass('d-none');
		} else if ($(this.m_sel.salaireTypeNet).prop("checked")) {
			$(this.m_sel.fraisBpAjoutMontantFraisAuSalaire).addClass('d-none');
		} else if ($(this.m_sel.salaireTypeCE).prop("checked")) {
			$(this.m_sel.fraisBpAjoutMontantFraisAuSalaire).addClass('d-none');
		} else if($(this.m_sel.salaireTypeHT).prop("checked")) {
			$(this.m_sel.fraisBpAjoutMontantFraisAuSalaire).removeClass('d-none');
		} else if($(this.m_sel.salaireTypeTTC).prop("checked")) {
			$(this.m_sel.fraisBpAjoutMontantFraisAuSalaire).removeClass('d-none');
		}
	}
}
