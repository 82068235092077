"use strict";

/* Section pour virer les warnings de netbeans concernant les variables globales*/
/* global toastr */

import { Ajax } from './ajax.js';
import { Dom } from './dom.js';
import { Debug } from './debug.js';
import { PFHBaseClass } from './pfhBaseClass.js';

export class CompteSalarieEditerFichePrestation extends PFHBaseClass {
	
	//---------------------//
	//-- DONNEES MEMBRES --//
	//---------------------//
	
	m_descriptifUpdated = false; // Flag qui défini si la page descriptif à été mis à jour
	m_optionsUpdated = false; // Flag qui défini si la page options à été mis à jour

	m_maxImgSize = 1 * 1024 * 1024; // Taille maximale des images : 1 Mo par défaut
	m_imgWidth = 500; // Largeur maximale des images
	m_imgHeight = 500; // Hauteur maximale des images

	//------------------//
	//-- CONSTRUCTEUR --//
	//------------------//
	
	constructor() {
		super(); // Appelle le constructeur de PFHBaseClass
		
		// Configuration des différents sélécteurs
		this.m_sel = {
			mainItem: '#creationFiche',
			
			// Navigation
			lstTabs: '.tabs-wrapper li', // listing des tabulations
			tabNavigation: 'a[data-bs-toggle="tab"]', // Liens contenu dans le listing
			
			btnPrecedent: '#btn-precedent', // Bouton précédent
			btnSuivant: '#btn-suivant', // Bouton suivant
			btnSauvegarde: '#btn-sauvegarde', // Bouton de sauvegarde
			btnShowRecap: '#btn-show-recap', // Bouton d'affichage du récap
			
			// Etape 1 : bouton choix du type de prestation
			lstBtnTypePrestation: '#choix-type-fiche [name="btn-type-prestation"]', // Liste des boutons de choix de type de prestation
			lstIcoTypePrestation: '#choix-type-fiche [name="ico-choix-type"]', // Liste des icones de choix de type de prestation
			
			// Etape 2 Decriptif
			designation: '#designation',
			description: '#description',
			budgetHtHorsVhr: '#budget_ht_hors_vhr',
			duree : '#duree',
			
			// Etape 3 Zones
			lstBtnAjouterDepartement: '[name="btn-ajouter-departement"]',
			lstBtnSupprimerDepartement: '[name="btn-supprimer-departement"]',
			departementsDisponibles1: '#departements-dispo-1',
			departementsSelect1: '#departements-sel-1',
			departementsDisponibles2: '#departements-dispo-2',
			departementsSelect2: '#departements-sel-2',
			
			// Etape 4 Options
			interieur: '[name="interieur"]',
			exterieur: '[name="exterieur"]',
			eclairage_inclus: '[name="eclairage_inclus"]',
			sonorisation_inclus: '[name="sonorisation_inclus"]',
			public_enfant: '[name="public_enfant"]',
			public_tout: '[name="public_tout"]',
			
			// Etape 5 Theme
			btnAjouterTheme: '#btn-ajouter-theme',
			btnSupprimerTheme: '#btn-supprimer-theme',
			unSelectedThemes: '#lst_themes',
			selectedThemes: '#lst_selected_themes',
			
			// Etape 6 Photos
			lstBtnAjoutePhotos: '[name="btn-ajoute-photo"]', // liste des Boutons d'ajout de photo
			lstBtnSupprimePhotos: '[name="btn-supprime-photo"]', // liste des Boutons de choix de fichiers
			photoPreview1: '#photoPreview1', // Conteneur de la prévisualisation de l'image 1
			imgPhotoPreview1: '#imgPhotoPreview1',
			photoPreview2: '#photoPreview2', // Conteneur de la prévisualisation de l'image 2
			imgPhotoPreview2: '#imgPhotoPreview2',
			
			// Etape 7 preview
			prestaPreview: '#presta-preview'
		};
		
		//-- CONFIGURATION GLOBALE --//
		
		// On met a jours la visibilité des boutons
		this.refreshButtonVisibility();
		
		// Taille maximale des images
		this.m_maxImgSize = this.m_imgWidth * this.m_imgHeight * 3; 
		
		//-- ACTIONS PAGINATION --//
		
		// EVENEMENT changement de page (AVANT)
		this.I('tabNavigation').on('show.bs.tab', (p_event) => this.onChangementDePageBefore(p_event));
		
		// EVENEMENT changement de page (APRES)
		this.I('tabNavigation').on('shown.bs.tab', (p_event) => this.onChangementDePageAfter(p_event));

		// Clique sur le bouton précédent
		this.I('btnPrecedent').click(() => this.onNavPagePrecedentRequested());

		// Clique sur le bouton suivant
		this.I('btnSuivant').click(() => this.onNavPageSuivantRequested());

		//-- ACTIONS PAGE TYPE --//
		
		// Clique sur le type de prestation
		this.I('lstBtnTypePrestation').click((p_event) => this.onTypePrestationClicked(p_event));

		//-- ACTIONS PAGE DESCRIPTIF --//

		// Si on modifie un champ
		['designation', 'description', 'budgetHtHorsVhr', 'duree'].forEach(field => {
				this.I(field).change(() => this.descriptifWasUpdated());
			});
		
		//-- ACTIONS PAGES ZONES --//
		
		this.I('lstBtnAjouterDepartement').click((p_event) => this.onAjouterDepartement(p_event));
		
		this.I('lstBtnSupprimerDepartement').click((p_event) => this.onSupprimerDepartement(p_event));
		
		//-- ACTIONS PAGE OPTIONS --//
		
		['interieur', 'exterieur', 'eclairage_inclus', 'sonorisation_inclus', 'public_enfant', 'public_tout'].forEach(field => {
				this.I(field).change(() => this.optionsWasUpdated());
			});
		
		//-- ACTIONS PAGE THEME --//
		
		this.I('btnAjouterTheme').click((p_event) => this.onAjouterSelectedTheme(p_event));
		
		this.I('btnSupprimerTheme').click((p_event) => this.onSupprimerSelectedTheme(p_event));
		
		//-- ACTIONS PAGE PHOTOS --//
		
		this.I('lstBtnAjoutePhotos').change((p_event) => this.onPhotoChanged(p_event));
		
		this.I('lstBtnSupprimePhotos').click((p_event) => this.onPhotoSupprimerClicked(p_event));
	}
	
	//----------------//
	//-- ACCESSEURS --//
	//----------------//
	
	item() {
		return this.I('mainItem');
	}
	
	//----------------//
	//-- PAGINATION --//
	//----------------//
	
	/**
	 * @returns {int} l'étape courante
	 */
	getCurrentStep() {
		// Sélectionner le lien actif parmi les onglets
    const activeTab = document.querySelector('.nav-link.active');

    // Si un onglet est actif
    if (activeTab) {
			// Extraire l'ID de l'onglet
			const activeTabId = activeTab.getAttribute('href');

			// Extraire le numéro de l'étape (on récupère le dernier caractère de l'ID)
			const currentStep = parseInt(activeTabId.replace('#etape', ''), 10);

			return currentStep;  // Retourne le numéro de l'étape courante
    }

    return 0;  // Si aucun onglet actif n'est trouvé, retourner 0 ou une autre valeur par défaut
	}
	
	/**
	 * Evènement appelé lors d'un changement de page
	 * Lorsqu'il y à un changement d'étape, selon les étapes, on sauvegarde les données
	 * @param {type} p_event
	 */
	onChangementDePageBefore(p_event) {
		const oldStep = this.getCurrentStep();
		
		switch (oldStep) {

			// On sauvegarde le descriptif (étape 2)
			case 2:
				this.savePageDescriptif();
				break;

			// Sauvegarde des options (étape 4)
			case 4:
				this.savePageOptions();
				break;
		}
	}

	onChangementDePageAfter() {
		if (this.getCurrentStep() === 7) {
			this.loadPreview();
		}
		
		// On vérifie l'affichage des différents boutons
		this.refreshButtonVisibility();
	}
			
	/**
	 * Clique sur le bouton précédent
	 */
	onNavPagePrecedentRequested() {
		const $activeTab = this.I('lstTabs').find('a.active');

		// On récupère le prochain onglet
		const $prevTab = $activeTab.parent('li').prev('li');
		
		if ($prevTab.length) {
			$prevTab.find('a[data-bs-toggle="tab"]').tab('show');
		} else {
			Debug.log('Aucun onglet suivant trouvé.');
		}
	}
	
	/**
	 * Clique sur le bouton suivant
	 */
	onNavPageSuivantRequested() {
		const $activeTab = this.I('lstTabs').find('a.active');
		
		// On récupère le prochain onglet
		const $nextTab = $activeTab.parent('li').next('li');
		
		if ($nextTab.length) {
			$nextTab.find('a[data-bs-toggle="tab"]').tab('show');
		} else {
			Debug.log('Aucun onglet suivant trouvé.');
		}
	}
	
	/**
	 * Rafraichis la visibilité des boutons en fonction de l'étape actuelle
	 */
	refreshButtonVisibility() {
		// Sauvegarder et récap sont désactivés pour le moment
		this.I('btnSauvegarde').hide();
		this.I('btnShowRecap').hide();

		// On récupère le numéro de l'étape
		const currentStep = this.getCurrentStep();
	
		// on rend le bouton précédent invisible si on est à l'étape 1 ou moins sinon on le rend visible
		Dom.setVisibility(this.I('btnPrecedent'), currentStep > 1);

		// on rend le bouton suivant invisible si on est à l'étape 5 ou plus sinon on le rend visible
		Dom.setVisibility(this.I('btnSuivant'), currentStep < 6);
	}
	
	//--------------------------//
	//-- PAGE TYPE PRESTATION --//
	//--------------------------//
	
	/**
	 * On clique sur le type de prestation
	 * @param {Event} p_event Evenement associé
	 */
	onTypePrestationClicked(p_event) {
		p_event.preventDefault();
		$.blockUI();

		const $currentElement = $(p_event.currentTarget);
		const typeEmployeur = $currentElement.attr('data-type-employeur');

		Ajax.postAsync(
			'AppSalarie/fichePrestationEnregistrerTypeFiche', {
				typeEmployeur: typeEmployeur
			},

		() => {
				$.unblockUI();

				const $icoSelection = $("#" + $currentElement.attr('data-ico'));

				// On change de page
				this.onNavPageSuivantRequested();
				this.I('lstIcoTypePrestation').removeClass('icoTypeEmployeurSelected');
				$icoSelection.addClass('icoTypeEmployeurSelected');
			},

			(p_result) => Ajax.defaultAsyncJSONErrorCallback(p_result, UNBLOCK_UI)
		);
	}
	
	//---------------------//
	//-- PAGE DESCRIPTIF --//
	//---------------------//
	
	descriptifWasUpdated() {
		this.m_descriptifUpdated = true;
	}
	
	savePageDescriptif() {
		// Si le descriptif n'est pas mis à jours on ne lance pas de sauvegarde
		if (this.m_descriptifUpdated === false) {
			return;
		}
		
		$.blockUI();
		Ajax.postAsync(
			'AppSalarie/fichePrestationEnregistrerDescriptif', {
				designation: this.I('designation').val(),
				description : this.I('description').val(),
				budgetHtHorsVhr : this.I('budgetHtHorsVhr').val(),
				duree : this.I('duree').val()
			},

			(p_result) => {
				this.m_descriptifUpdated = false;
				Ajax.defaultAsyncJSONSuccessCallback(p_result, UNBLOCK_UI);
			},

			(p_result) => Ajax.defaultAsyncJSONErrorCallback(p_result, UNBLOCK_UI)
		);
	}
	
	//----------------//
	//-- PAGE ZONES --//
	//----------------//

	onAjouterDepartement(p_event) {
		p_event.preventDefault();
		
		const listType = $(p_event.currentTarget).attr('data-list-type');
		const otherListType = listType === '1' ? '2' : '1';
		const vhr = $(p_event.currentTarget).attr('data-vhr');
		
		const $availableDepartements = this.I(`departementsDisponibles${listType}`);
		const $otherAvailableDepartements = this.I(`departementsDisponibles${otherListType}`);
		
		const lstIdDepartements = $availableDepartements.find('option:selected').map(function () {
        return $(this).val(); // Retourner la valeur de chaque option sélectionnée
    }).get(); // Convertir l'objet jQuery en tableau JavaScript
		
		// Si le tableau est vide, il n'y à pas de séléction et on ne fait rien
		if (lstIdDepartements.length === 0) {
			return;
		}
		
		$.blockUI();
		Ajax.postAsync(
			'AppSalarie/fichePrestationAjouterDepartement', {
				lstIdDepartements: lstIdDepartements.join(','),
				vhr: vhr
			},

			() => {
				$.unblockUI();
				// On supprime également sur la seconde liste ce que l'on va transférer
				Dom.selectRemoveOptionsFromSecondSelect($availableDepartements, $otherAvailableDepartements);
				Dom.selectMoveOptionsToAnotherSelect($availableDepartements, this.I(`departementsSelect${listType}`));
			},
			
			(p_result) => Ajax.defaultAsyncJSONErrorCallback(p_result, UNBLOCK_UI)
		);
	}
		
	onSupprimerDepartement(p_event) {
		p_event.preventDefault();
		
		const listType = $(p_event.currentTarget).attr('data-list-type');
		const $currentSelect = this.I(`departementsSelect${listType}`);
		
		const lstIdDepartements = $currentSelect.find('option:selected').map(function () {
        return $(this).val(); // Retourner la valeur de chaque option sélectionnée
    }).get(); // Convertir l'objet jQuery en tableau JavaScript
		
		Debug.log($currentSelect);
		Debug.log(lstIdDepartements);
		
		// Si le tableau est vide, il n'y à pas de séléction et on ne fait rien
		if (lstIdDepartements.length === 0) {
			return;
		}
		
		$.blockUI();
		Ajax.postAsync(
			'AppSalarie/fichePrestationSupprimerDepartement', {
				lstIdDepartements: lstIdDepartements.join(',')
			},

			() => {
				$.unblockUI();
				Dom.selectMoveOptionsToMultipleSelects($currentSelect, [ 
						this.I('departementsDisponibles1'), 
						this.I('departementsDisponibles2') 
					]);
			},
			
			(p_result) => Ajax.defaultAsyncJSONErrorCallback(p_result, UNBLOCK_UI)
		);
	}

	//------------------//
	//-- PAGE OPTIONS --//
	//------------------//
	
	optionsWasUpdated() {
		this.m_optionsUpdated = true;
	}
	
	savePageOptions() {
		// Si les options n'ont pas été mis à jours on ne lance pas de sauvegarde
		if (this.m_optionsUpdated === false) {
			return;
		}

		$.blockUI();
		Ajax.postAsync(
			'AppSalarie/fichePrestationEnregistrerOptions', {
				interieur: $('input[name="interieur"]:checked').val(),
				exterieur : $('input[name="exterieur"]:checked').val(),
				eclairageInclus : $('input[name="eclairage_inclus"]:checked').val(),
				sonorisationInclus : $('input[name="sonorisation_inclus"]:checked').val(),
				publicEnfant : $('input[name="public_enfant"]:checked').val(),
				publicTout : $('input[name="public_tout"]:checked').val()
			},

			(p_result) => {
				this.m_optionsUpdated = false;
				Ajax.defaultAsyncJSONSuccessCallback(p_result, UNBLOCK_UI);
			},

			(p_result) => Ajax.defaultAsyncJSONErrorCallback(p_result, UNBLOCK_UI)
		);
	}
	
	//----------------//
	//-- PAGE THEME --//
	//----------------//
	
	onAjouterSelectedTheme(p_event) {
		p_event.preventDefault();
		
		const $unselectedThemes = this.I('unSelectedThemes');
		
		const lstIdThemes = $unselectedThemes.find('option:selected').map(function () {
        return $(this).val(); // Retourner la valeur de chaque option sélectionnée
    }).get(); // Convertir l'objet jQuery en tableau JavaScript
		
		// Si le tableau est vide, il n'y à pas de séléction et on ne fait rien
		if (lstIdThemes.length === 0) {
			return;
		}
		
		$.blockUI();
		Ajax.postAsync(
			'AppSalarie/fichePrestationAjouterTheme', {
				lstIdThemes: lstIdThemes.join(',')
			},

			() => {
				$.unblockUI();
				Dom.selectMoveOptionsToAnotherSelect($unselectedThemes, this.I('selectedThemes'));
			},
			
			(p_result) => Ajax.defaultAsyncJSONErrorCallback(p_result, UNBLOCK_UI)
		);
	}
		
	onSupprimerSelectedTheme(p_event) {
		p_event.preventDefault();
		
		const $selectedThemes = this.I('selectedThemes');
		
		const lstIdThemes = $selectedThemes.find('option:selected').map(function () {
        return $(this).val(); // Retourner la valeur de chaque option sélectionnée
    }).get(); // Convertir l'objet jQuery en tableau JavaScript
		
		// Si le tableau est vide, il n'y à pas de séléction et on ne fait rien
		if (lstIdThemes.length === 0) {
			return;
		}
		
		$.blockUI();
		Ajax.postAsync(
			'AppSalarie/fichePrestationSupprimerTheme', {
				lstIdThemes: lstIdThemes.join(',')
			},

			() => {
				$.unblockUI();
				Dom.selectMoveOptionsToAnotherSelect($selectedThemes, this.I('unSelectedThemes'));
			},
			
			(p_result) => Ajax.defaultAsyncJSONErrorCallback(p_result, UNBLOCK_UI)
		);
	}

	//-----------------//
	//-- PAGE PHOTOS --//
	//-----------------//
	
	checkImage(p_file) {
		return new Promise((resolve, reject) => {
			// Vérifier si un fichier a été sélectionné
			if (!p_file) {
				return;
			}

			// Vérifier le type de fichier
			if (!p_file.type.match('image/jpeg')) {
				reject('Seuls les fichiers JPEG sont autorisés.');
				return;
			}

			// Vérifier la taille du fichier
			if (p_file.size > this.m_maxImgSize) {
				reject(`La taille du fichier ne doit pas dépasser ${this.m_maxImgSize} octets.`);
				return;
			}

			// Vérifier les dimensions de l'image
			const img = new Image();
			img.src = URL.createObjectURL(p_file);

			img.onload = () => {
				if (img.width !== this.m_imgWidth || img.height !== this.m_imgHeight) {
					reject(`La taille de l'image doit être de ${this.m_imgWidth}x${this.m_imgHeight} pixels.`);
				} else {
					resolve(true); // Toutes les vérifications sont correctes
				}
			};

			img.onerror = () => {
				reject('Le fichier sélectionné n\'est pas une image valide.');
			};
		});
	}
	
	onPhotoChanged(p_event) {
		const file = p_event.target.files[0]; // Récupérer le fichier sélectionné
		const photoNumber = $(p_event.currentTarget).attr('data-photo-number');
		const $photoPreview = this.I(`photoPreview${photoNumber}`);
		const $imgPhotoPreview = this.I(`imgPhotoPreview${photoNumber}`);
		
		this.checkImage(file)
			// Le chargement est correct
			.then(() => {
				const formData = new FormData();
				formData.append('image', file); // Ajouter l'image au FormData
				formData.append('photoNumber', photoNumber);

				// On upload le fichier
				$.blockUI();
				Ajax.upload(
					'AppSalarie/fichePrestationUploadPhoto', 
				
					formData, 

					(p_result) => {
						Ajax.defaultAsyncJSONSuccessCallback(p_result, UNBLOCK_UI);
						
						// On créer une nouvelle source avec un timestamp afin de forcer le rafraichissement
						const timestamp = new Date().getTime(); 
						const newSrc = `/media/img/catalogue${p_result.imageURL}?t=${timestamp}`;
						
						$imgPhotoPreview.attr('src', newSrc);

						Dom.setVisibility($photoPreview, true);
						p_event.target.value = '';
					},
					
					// L'upload coté serveur à échoué
					(p_result) => {
						Ajax.defaultAsyncJSONErrorCallback(p_result, UNBLOCK_UI);
						
						// Réinitialiser l'input
						p_event.target.value = '';
					}
				);
			})
			
			// Le chargement est incorrect
			.catch((p_errorMessage) => {
				toastr.error(p_errorMessage);
				
				// Réinitialiser l'input
				p_event.target.value = ''; 
			});
	}
	
	onPhotoSupprimerClicked(p_event) {
		p_event.preventDefault();
		
		const photoNumber = $(p_event.currentTarget).attr('data-photo-number');
		const $photoPreview = this.I(`photoPreview${photoNumber}`);
		
		// On supprime le fichier
		$.blockUI();
		Ajax.postAsync(
			'AppSalarie/fichePrestationSupprimerPhoto', {
				'photoNumber': photoNumber}, 

			(p_result) => {
				Ajax.defaultAsyncJSONSuccessCallback(p_result, UNBLOCK_UI);
				
				// On rend invisible l'image
				Dom.setVisibility($photoPreview, false);
			},

			// L'upload coté serveur à échoué
			(p_result) => Ajax.defaultAsyncJSONErrorCallback(p_result, UNBLOCK_UI)
		);
	}
	
	
	//------------------//
	//-- PAGE PREVIEW --//
	//------------------//
	
	loadPreview() {
		// On supprime le fichier
		$.blockUI();
		Ajax.postAsync(
			'AppSalarie/getTplPrestationDetail', {}, 

			(p_result) => {
				Ajax.defaultAsyncJSONSuccessCallback(p_result, UNBLOCK_UI);
				
				this.I('prestaPreview').html(p_result.html);
			},

			// L'upload coté serveur à échoué
			(p_result) => {
				Ajax.defaultAsyncJSONErrorCallback(p_result, UNBLOCK_UI);
				
				this.I('prestaPreview').html('');
			}
		);
	}
}
