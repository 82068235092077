/* Section pour virer les warnings de netbeans concernant les variables globales*/
/* global toastr */

"use strict";

import { Ajax } from './ajax.js';
import { Dom } from './dom.js';
import { Debug } from './debug.js';
import { PFHBaseClass } from './pfhBaseClass.js';

function getSharedFormRulesAdresse() {
	return {
		rue1: { required: true, alphanumeric: true },
		code_postal: { required: true, checkValidCodePostal: true },
		ville: { required: true },
		id_pays: { required: true }
	};
}

function getSharedMsgRulesAdresse() {
	return {
		rue1: { required: "Merci de renseigner votre adresse"},
		code_postal: { required: "Merci de renseigner votre code postal"},
		ville: { required: "Merci de renseigner votre ville" },
		id_pays: { required: "Merci de renseigner votre pays" }
	};
}
		
function getSharedFormRulesInscriptionClient() {
	return {
		identifiant: { required: true },
		mot_de_passe: { required: true },
		civilite : { required: true },
		email : { required: true, valid_email: true },
		telephone : { required: true, number:true },
		nom : { required: true, alphanumeric:true },
		prenom : { required: true, alphanumeric:true },
		...getSharedFormRulesAdresse(),
		rue2 : { alphanumeric:true },
		rue3 : { alphanumeric:true },
		categorie : { required: true, number: true }
	};
}
		
class CompteSociete extends PFHBaseClass {
	//---------------------//
	//-- DONNEES MEMBRES --//
	//---------------------//
	
	m_formRules = {};
	
	m_formMsg = {};
	
	//------------------//
	//-- CONSTRUCTEUR --//
	//------------------//
	
	constructor() {
		super(); // Appelle le constructeur de PFHBaseClass
		
		this.m_formRules = {
			/** Règles d'inscription d'un salarié par un client */
			InscriptionSalarie: {
				civilite: { required: true },
				nom: { required: true },
				prenom: { required: true },
				...getSharedFormRulesAdresse(),
				telephone: { number: true, required:false },
				email: { required: true, valid_email: true },
				id_pays_naissance: { required: true },
				date_naissance: { required:true, dateBR:true },
				dept_naissance: { required: true },
				lieu_naissance: { required: true },
				id_pays_nationalite: { required: true },
				num_secu: { required: true, number:true, num_secu : true },		
				num_cs: { required: true, num_cs : true }
			},

			/** Règles d'inscription d'un nouveau client particulier */
			InscriptionClientParticulier: {
				...getSharedFormRulesInscriptionClient()
			},

			/** Règles d'inscription d'un nouveau client */
			InscriptionClient: {
				...getSharedFormRulesInscriptionClient(),
				nom_societe : { required: true, alphanumeric:true },
				forme_juridique : { required: true, alphanumeric:true },
				fonction : { required: true },
				siret : { required: true, siret : true },
				tva_intra : { tva: true },
				ape : { required: true, ape :true }
			},

			/** Règles de modification de l'adresse d'un salarie */
			ModifierAdresseSalarie: {
				telephone: { number: true },
				email: { required: false, valid_email: true },
				...getSharedFormRulesAdresse(),
				rue2: { alphanumeric: true }
			},

			/** Règles de modification d'un salarie */
			ModifierSalarie: {
				salaire: {number: true}
			},

			/** Règles de modification des coordonnées du client */
			ModifierCoordonnees: {
				nom_societe: {required: true, alphanumeric: true },
				...getSharedFormRulesAdresse(),
				rue2: { alphanumeric: true },
				rue3: { alphanumeric: true }
			},

			/** Règles de modification des données sociales du client */
			ModifierDonneesSociales: {
				effectif: {required: true, number: true},
				date_obtention_licence: {required: true}
			},

			/** Règles de modification du mot de passe du client */
			ModifierMotDePasse: {
				mot_de_passe_ancien: {required: true},
				mot_de_passe_nouveau: {required: true},
				mot_de_passe_confirmation: {required: true}
			},

			/** Règles de modification d'une prestation */
			ModifierPrestation: {
				designation: {required: true} 
			},

			/** Règles d'ajout d'une prestation */
			AjouterPrestation: {
				designation: {required: true}
			}
		};

		this.m_formMsg = {
			/** Messages associés aux règles de modification de l'adresse d'un salarie */
			ModifierAdresseSalarie: {
				email: { valid_email: "Merci de renseigner un email valide" },
				...getSharedMsgRulesAdresse()
			},

			/** Messages associés aux règles de modification d'un salarie */
			ModifierSalarie: {
				salaire: {number: "Merci de renseigner des valeurs numériques"}
			},

			/** Messages associés aux règles de modification des coordonnées du client */
			ModifierCoordonnees: {
				nom_societe: { required: "Merci de renseigner votre nom de société" },
				...getSharedMsgRulesAdresse()
			},

			/** Messages associés aux règles de modification d'une prestation */
			ModifierPrestation: {
				designation: {required: "Vous devez saisir une désignation"}
			},

			/** Messages associés aux règles d'ajout d'une prestation */
			AjouterPrestation: {
				designation: {required: "Vous devez saisir une désignation"}
			}
		};
		
		// Configuration des différents sélécteurs
		this.m_sel = {
			formModifierPrestation: '#form-societe-modifier-prestation',
			formAjouterPrestation: '#form-societe-ajouter-prestation',
			bpSupprimerPrestation: '[name=ext-supprimer-prestation]',
			leNumSecu: '#num_secu',
			bpAjouterSalarie: '#btn-ajout-salarie',
			divAjoutSalarie: '#ajout-salarie',
			divAjoutSalarieSuccess: '#salarie-invitation-success',
			
			// Inscription de salarie
			formSocieteInscriptionSalarie: '#form-societe-inscription-salarie',
			cbPasDeVisiteMedicale: '#pas-de-visite-medicale',
			divExplicatifVisiteMedicale: '#form-visite-medicale-explicatif',
			cbPasDeCongesSpectacle: '#pas-de-conges-spectacle',
			divPasDeCongesSpectacle: '#form-conges-spectacle-explicatif',
			leNumCs: '#num_cs',
			
			leSearchSalarie: "#input-search-salaries-compte-ext",
			tableResultSearchSalarie: '#table-results-salaries',
			
			formSocieteModifierCoordonnees: '#form-societe-modifier-coordonnees',
			formSocieteModifierDonneesSociales: '#form-societe-modifier-donnees-sociales',
			formSocieteModifierMdp: '#form-societe-modifier-mot-de-passe',
			
			// Initialisation des sélécteurs de la page
			formSocieteInscription: '#form-societe-inscription',
			cbAdresseCourrierDifferente: '#adresse-courrier-differente',
			divGroupAddress: '#group-address',
			cbExternalisationActif: '#externalisation-actif',
			divGroupExternalisation: '#group-externalisation',
			selInscriptionCategorie: '#inscription-categorie'
		};
		
		const pageCourante = Dom.getPageName();

		switch (pageCourante) {

			//----------------//
			//-- N°D'OBJETS --//
			//----------------//

			//-- PAGE MODIFIER N°D'OBJETS --//
			case 'ext-modifier-prestation' : this.initPageModifierPrestation(); break;

			//-- PAGE AJOUTER UN N°D'OBJETS --//
			case 'ext-ajouter-prestation' : this.initPageAjouterPrestation(); break;

			//-- PAGE MES N°D'OBJETS --//
			case 'ext-lister-prestations' : this.initPageListerPrestations(); break;

			//------------------//
			//-- MES SALARIES --//
			//------------------//

			//-- PAGE AJOUTER SALARE SAISIE NUM SECU --//
			case 'ext-ajouter-salarie':  this.initPageExtAjouterSalarie(); break;

			//-- PAGE AJOUTER SALARE INSCRIPTION --//
			case 'ext-inscription-salarie' : this.initPageExtInscriptionSalarie(); break;
			
			//-- PAGE LISTER SALARIES --//
			case 'ext-lister-salarie' : this.initPageExtListerSalarie(); break;

			//-- PAGE MODIFICATION DE SALARIE --//
			case 'ext-modifier-salarie' : this.initPageExtModifierSalarie(); break;

			//---------------------------//
			//-- PAGES LISTER DOSSIERS --//
			//---------------------------//

			case 'societe-lister-dossiers':
			case 'societe-lister-dossiers-archives': this.initPageListerDossiers(); break;

			//----------------//
			//-- MON COMPTE --//
			//----------------//

			//-- PAGE MON COMPTE / MODIFICATION --//
			case 'ext-modifier-compte': this.initPageExtModifierCompte(); break;

			//-----------------//
			//-- INSCRIPTION --//
			//-----------------//

			//-- PAGE INSCRIPTION CLIENT --//
			case 'societe-inscription': this.initPageInscription(); break;
		}
	}
	
	//---------------------//
	//-- INITIALISATIONS --//
	//---------------------//
	
	initPageModifierPrestation() {
		/** Formulaire de modification d'un spectacle */
		Dom.initFormValidation(
			this.I('formModifierPrestation'), 
			'AppCompteSociete/modifierPrestation', 
			this.m_formRules.ModifierPrestation,
			this.m_formMsg.ModifierPrestation);
	}

	initPageAjouterPrestation() {
		Dom.initFormValidation(
			this.I('formAjouterPrestation'),
			'AppClient/ajouterPrestation', 
			this.m_formRules.AjouterPrestation, 
			this.m_formMsg.AjouterPrestation);
	}
	
	initPageListerPrestations() {
		/** 
		 * On clique sur la supression d'un spectacle.
		 * Le listing n'est pas dynamique
		 * @param {Event} p_event Evenement associé
		 */
	 this.I('bpSupprimerPrestation').click((p_event) => {
		 p_event.preventDefault();

		 if (!confirm("Etes-vous sûr de vouloir supprimer ce numéro d'objet ?")) {
			 return false;
		 }

		 Ajax.postAsync (
				'AppClient/supprimerPrestation', {
				 crptIdPrestation: $(p_event.currentTarget).attr("data-crpt-id-prestation")
				},
				
				(p_result) => Ajax.defaultAsyncJSONSuccessCallback(p_result),
				
				(p_result) => Ajax.defaultAsyncJSONErrorCallback(p_result)
			);
		});
	}
	
	initPageExtAjouterSalarie() {

		/** 
		 * clique sur le bouton "Ajouter un salarié" 
		 * @param {Event} p_event Evenement associé
		 */
		this.I('bpAjouterSalarie').click((p_event) => {
			p_event.preventDefault();

			Ajax.postAsync (
				'AppCompteSociete/checkBeforeInscriptionSalarie', {
					num_secu: this.I('leNumSecu').val()
				},
				
				(p_result) => {
					if (parseInt(p_result.error) === 1) {
						this.I('divAjoutSalarie').html(p_result.html);
						this.I('divAjoutSalarie').show();
						this.I('divAjoutSalarieSuccess').hide();
					}
					else {
						window.location = 'ext-inscription-salarie';
					}
				},
				
				(p_result) => Ajax.defaultAsyncJSONErrorCallback(p_result)
			);

			return false;
		});
	}
	
	initPageExtInscriptionSalarie() {
			/**
			 * Formulaire d'inscription d'un nouveau salarié
			 */
			Dom.initFormValidation(
				this.I('formSocieteInscriptionSalarie'),
				'AppCompteSociete/ajouterSalarie', 
				this.formRulesInscriptionSalarie, 
				{});

			/** 
			 * On clique sur l'icone pas de visite médicale 
			 */
			this.I('cbPasDeVisiteMedicale').click(() => {
				const checked = this.I('cbPasDeVisiteMedicale').is(":checked");
					
				this.I('divExplicatifVisiteMedicale').toggle(checked);
			});

			/** 
			 * On clique sur l'icone pas de congés spectacles 
			 */
			this.I('cbPasDeCongesSpectacle').click(() => {
				const checked = this.I('cbPasDeCongesSpectacle').is(":checked");
				
				this.I('divPasDeCongesSpectacle').toggle(checked);
				this.I('leNumCs').val(checked ? 'A000000' : '');
			});
		}
		
	initPageExtListerSalarie() {
		/** 
		 * A chaques fois que l'on tape du texte on lance une recherche de salarié
		 * Et on affiche le résultat dans le tableau de résultat
		 * @param {Event} p_event Evenement associé
		 */
		this.I('leSearchSalarie').keyup((p_event) => {
			const search = $(p_event.currentTarget).val();

			Ajax.postAsync(
				'AppClient/rechercheSalaries', {
					search: search 
				},
				
				(p_result) => { this.I('tableResultSearchSalarie').html(p_result.html); },
				
				(p_result) => Ajax.defaultAsyncJSONErrorCallback(p_result)
			);
		});

		/** 
		 * Changement de l'ordre des salaries 
		 * Délégation de l'évenement via "$(document).on()" afin de gérer les ajouts dynamiques
		 * @param {Event} p_event Evenement associé
		 */
		$(document).on('click', '[data-ordering-salaries=true]', (p_event) => {
			p_event.preventDefault(); // On désactive l'action par défaut du click

			const ordering = $(p_event.currentTarget).attr('data-ordering');

			// On génère un nouveau listing et on remplace le précédent
			Ajax.postAsync(
				'AppSalarie/orderingSalaries', {
					ordering: ordering 
				},
				
				(p_result) => { this.I('tableResultSearchSalarie').html(p_result.html); },
				
				(p_result) => Ajax.defaultAsyncJSONErrorCallback(p_result)
			);
		});
	}
	
	initPageExtModifierSalarie() {
		/**
		 * Formulaire de modification d'un salarié
		 */
		Dom.initFormValidationById(
			'#form-societe-modifier-salarie',
			'AppCompteSociete/modifierSalarie', 
			this.m_formRules.ModifierSalarie, 
			this.m_formMsg.ModifierSalarie);

		/**
		 * Formulaire de modification de l'adresse d'un salarié
		 */
		Dom.initFormValidationById(
			'#form-societe-modifier-adresse-salarie',
			'AppCompteSociete/modifierSalarieAdresse', 
			this.m_formRules.ModifierAdresseSalarie, 
			this.m_formMsg.ModifierAdresseSalarie);
	}
	
	initPageListerDossiers() {
		/** 
		 * Envoi de message pour un dossier 
		 * @param {Event} p_event Evenement associé
		 */
		$('[name="btn-dossier-envoyer-message"').click(function(p_event) {
			p_event.preventDefault();

			var numDossier = $(this).attr('data-num_dossier');			

			$.blockUI();
			Ajax.postAsync(
				'AppCompteSociete/envoyerMessageDossier', {
					num_dossier: numDossier,
					message: $('#message-' + numDossier).val()
				}, 
				
				(p_result) => {
					$("#modal-" + numDossier).modal( "hide" );
					Ajax.defaultAsyncJSONSuccessCallback(p_result, UNBLOCK_UI);
				},
				
				(p_result) => Ajax.defaultAsyncJSONErrorCallback(p_result, UNBLOCK_UI)
			);
		});
	}

	initPageExtModifierCompte() {
		/**
		 * Formulaire de modification de l'adresse de facturation
		 */
		Dom.initFormValidation(
			this.I('formSocieteModifierCoordonnees'),
			'AppCompteSociete/modifierCoordonnees', 
			this.m_formRules.ModifierCoordonnees, 
			this.m_formMsg.formMsgModifierCoordonnees);

		/**
		 * Formulaire de modification des données sociales
		 */
		Dom.initFormValidation(
			this.I('formSocieteModifierDonneesSociales'),
			'AppCompteSociete/modifierDonneesSociales',
			this.m_formRules.ModifierDonneesSociales, 
			{});

		/**
		 * Formulaire de modification du mot de passe
		 */
		Dom.initFormValidation(
			this.I('formSocieteModifierMdp'), 
			'AppCompteSociete/modifierMotDePasse',
			this.m_formRules.ModifierMotDePasse,
			{});
	}
	
	initPageInscription() {
		/** 
		 * On clique sur le checkbox adresse de courrier different 
		 * On affiche ou cache l'élément
		 */
		this.I('cbAdresseCourrierDifferente').click(() => {
			this.I('divGroupAddress').toggle(this.I('cbAdresseCourrierDifferente').is(":checked"));
		});

		/** 
		 * On clique sur le checkbox externalisation 
		 * On affiche ou cache l'élément
		 */
		this.I('cbExternalisationActif').click(() => {
			this.I('divGroupExternalisation').toggle(this.I('cbExternalisationActif').is(":checked"));
		});

		/**
		 * Si on change de catégorie de client 
		 * @param {Event} p_event Evenement associé
		 */
		this.I('selInscriptionCategorie').change((p_event) => {
			const value = parseInt($(p_event.currentTarget).val());

			// On affiche ou on cache les champs spécifiques aux particuliers
			const isParticulier = (value === 5);
			$('.inscription-client-particulier-hidden').toggle(!isParticulier);

			// On modifie également les règles de validation
			Dom.replaceFormRules(this.I('formSocieteInscription'), isParticulier ? this.m_formRules.InscriptionClientParticulier : this.m_formRules.InscriptionClient);
		});

		/**
		 * Formulaire d'inscription d'une nouvelle société 
		 */
		Dom.initFormValidation(
			this.I('formSocieteInscription'),
			'AppClient/inscriptionClient',
			this.m_formRules.InscriptionClient, 
			{});
	}
}

//------------------------------------------------------------------//
//------------------------------------------------------------------//
//------------------------- DOCUMENT READY -------------------------//
//------------------------------------------------------------------//
//------------------------------------------------------------------//

$(document).ready(function() {
	const compteSociete = new CompteSociete();
});