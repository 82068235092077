"use strict";

import { Ajax } from './ajax.js';
import { Debug } from './debug.js';

export class Calendar {
	//---------------------//
	//-- DONNEES MEMBRES --//
	//---------------------//
		
	// Si on utilise flapickr
	m_preventOnChange = false; // Variable de garde pour contrôler l'appel de onChange
	m_flatpickrInstance = null;
	
	m_sel = {
		mainItem : '',
		divCalendar : '#calendar-salarie-prestation',
		divCalendarResults : '#table-salarie-result'
	};
	
	m_item = {
		mainItem : null,
		divCalendar : null,
		divCalendarResults : null
	};
	
	//------------------//
	//-- CONSTRUCTEUR --//
	//------------------//
	
	/**
	 * Initialisation d'un calendrier et de son fonctionnnement multi date
	 * @param {type} p_mainItemId
	 * @param {type} p_divCalendarId
	 * @param {type} p_divCalendarResults 
	 */
	constructor(p_mainItemId, p_divCalendarId, p_divCalendarResults) {
		
		// Paramètres par défaut (compatible IE)
		if (p_divCalendarId === undefined) p_divCalendarId=null;
		if (p_divCalendarResults === undefined) p_divCalendarResults=null;
		
		this.m_sel.mainItem = p_mainItemId;
		if (p_divCalendarId) this.m_sel.divCalendarId = p_divCalendarId;
		if (p_divCalendarResults) this.m_sel.divCalendarResults = p_divCalendarResults;
		
		// On configure le datePicker
		this.m_flatpickrInstance = this.itemCalendar().flatpickr({
			dateFormat: 'd/m/Y',
			mode: "multiple",
			locale: 'fr',
			inline: true,
			onChange: (p_selectedDates, p_dateStr, p_instance) => { 
					if (this.m_preventOnChange) {
						  return; // Si la variable est vraie, on ignore l'appel
					}
					this.onDayClicked(p_selectedDates, p_dateStr, p_instance); 
				}
			});
		
		/**
		 * Gestion des click sur les éléments dynamiques
		 * @param {Event} p_event Evenement associé
		 */
		$(document).on('click', '[name="btn-remove-date-embauche"]', (p_event) => {
			const $item = $(p_event.currentTarget); // Item cliqué
			const name = $item.attr('name'); // Nom de l'item cliqué
			
			switch (name) {
				case 'btn-remove-date-embauche' :
					const trBlock = $item.parent().parent();
					this.removeDate(trBlock.attr('data-id-date-contrat'));
					break;
			}
		});
		
		/**
		 * Gestion des click sur les éléments dynamiques
		 * @param {Event} p_event Evenement associé
		 */
		$(document).on('change', '[name="nbCachets"], [name="nbRepetitions"], [name="nbHeures"], #typeCachetDeRepet', (p_event) => {
			const $item = $(p_event.currentTarget); // Item cliqué
			const name = $item.attr('name'); // Nom de l'item cliqué
			switch (name) {
				case 'nbCachets' :
				case 'nbRepetitions' :
				case 'nbHeures' :
					this.onDateChanged(p_event);
					break;
				
				case 'typeCachetDeRepet' : 
					this.onTypeRepetChanged(p_event);
					break;
			}
		});
	}

	//---------------------//
	//-- ACCESSEURS ITEM --//
	//---------------------//
	
	getItem(p_itemName) {
		if (this.m_item[p_itemName] === null) {
			this.m_item[p_itemName] = $(this.m_sel[p_itemName]);
		}
		return this.m_item[p_itemName];
	}
	
	itemCalendar() { return this.getItem('divCalendar'); }
		
	//----------------//
	//-- ACCESSEURS --//
	//----------------//
	
	nbSelectedDates() {
		return this.m_flatpickrInstance.selectedDates.length;
	}
	
	//------------//
	//-- DIVERS --//
	//------------//
	
	/**
	 * Switch une date
	 * @param {Date} p_date au format "dd/mm/yyyy"
	 */
	switchDate(p_date) {
		
		$.blockUI();
		Ajax.postAsync (
			'AppEmbauche/contratSwitchDateTravaillee', {
				date: this.formatDateToDDMMYYYY(p_date)
			}, 
			
			(p_result) => {
				// On à modifier les résultats, on modifie les boutons latéraux
				this.setCalendarHtmlContent(p_result.html);
				$.unblockUI();
			}, 
			
			(p_result) => {
				// On annule la modification
				this.switchCalendarDate(p_date);

				$.unblockUI();
				Ajax.defaultAsyncJSONErrorCallback(p_result);
			}
		);
	}
	
	/**
	 * Supprime la date dont l'ID et la date sont passés en paramètres
	 */
	removeAllDates() {
		
		Ajax.postAsync (
			'AppEmbauche/contratSupprimerDatesTravaillee', {},
			
			(p_result) => {
				// On à modifié les résultats, on rafraichis
				this.setCalendarHtmlContent(p_result.html);
			},
			
			(p_result) => Ajax.defaultAsyncJSONErrorCallback(p_result)
		);
	}
	
	/**
	 * Supprime la date dont l'ID et la date sont passés en paramètres
	 * @param {type} p_idDateContrat
	 */
	removeDate(p_idDateContrat) {
		$.blockUI();
		
		Ajax.postAsync(
			'AppEmbauche/contratSupprimerDateTravaillee', {					
				idDateContrat: p_idDateContrat
			}, 
			
			(p_result) => {
				// On à modifié les résultats, on rafraichis
				this.setCalendarHtmlContent(p_result.html);
				$.unblockUI();
			},
			
			(p_result) => {
				$.unblockUI();
				Ajax.defaultAsyncJSONErrorCallback(p_result);
			}
		);
	}
	
	/**
	 * Remplace les dates du résultat avec le contenu HTML passé en paramètre
	 * @param {type} p_htmlContent
	 * @returns {undefined}
	 */
	setCalendarHtmlContent(p_htmlContent) {
		
		// On remplace le HTML
		$(this.m_sel.divCalendarResults).html(p_htmlContent);
		
		// On rafraichis le calendrier
		this.checkCalendarDateSelected();
	}
	
	//------------//
	//-- EVENTS --//
	//------------//
		
	/**
	 * Evenement appelé lors de la modification d'une date (nbcachets / repet / hetres, etc...)
	 * @param {Event} p_event Evenement associé
	 */
	onDateChanged(p_event) {
		
		p_event.preventDefault();
		const item = $(p_event.currentTarget);

		const idDateContrat = item.attr('data-id-date-contrat');
		
		var nbCachets = $('#nbCachets_' + idDateContrat);
		var nbRepetitions = $('#nbRepetitions_' + idDateContrat);
		var nbHeures = $('#nbHeures_' + idDateContrat);

		Ajax.postAsync (
			'AppEmbauche/contratMajDateTravaillee', {					
				idDateContrat: idDateContrat,
				nbCachets: nbCachets.val(),
				nbRepetitions: nbRepetitions.val(),
				nbHeures: nbHeures.val()
			}, 
			
			(p_result) => {
				// On à modifié les résultats, on rafraichis
				nbCachets.val(p_result.nbCachets);
				nbRepetitions.val(p_result.nbRepetitions);
				nbHeures.val(p_result.nbHeures);

				if (p_result.compteRendu) toastr.error(p_result.compteRendu);
			},
			
			(p_result) => Ajax.defaultAsyncJSONErrorCallback(p_result)
		);
	}
	
	/**
	 * On clique sur une date dans le calendrier
	 * @param {Array[Date]} p_selectedDates Tableau contenant toutes les dates actuellement sélectionnées dans le calendrier
	 * @param {String} p_dateStr chaîne représentant les dates sélectionnées formatées selon l'option dateFormat définie dans la configuration de flatpickr
	 * @param {FlatpickrInstance} p_instance Instance actuelle de flatpickr associée à l'élément
	 */
	onDayClicked(p_selectedDates, p_dateStr, p_instance) {

		// On récupère la dernière date séléctionnée
		const date = p_instance.latestSelectedDateObj; //this.formatDateToDDMMYYYY(p_instance.latestSelectedDateObj);
		
		// ATTENTION ASYNCHRONE
		this.switchDate(date, false);
		
		// Désactive la propagation de l'évenement à bootstrap
		return false; 
	}
	
	/**
	 * On modifie le type de cachets de répétition
	 * @param {type} p_event
	 */
	onTypeRepetChanged(p_event) {
		p_event.preventDefault();

		$.blockUI();
		Ajax.postAsync (
			'AppEmbauche/contratMajTypeCachetDeRepet', {					
				cachetDeRepet: $('#typeCachetDeRepet').val()
			}, 
			
			(p_result) => {
				$.unblockUI();
				Ajax.defaultAsyncJSONSuccessCallback(p_result);
			},
			
			(p_result) => {
				$.unblockUI();
				Ajax.defaultAsyncJSONErrorCallback(p_result);
			}
		);	
	}
	
	//---------------------------//
	//-- EDITION DU CALENDRIER --//
	//---------------------------//
	
	/**
	 * @param {bool} p_preventOnChange si vrais, alors le calendrier sera modifié sans appel à la fonction onChange
	 */
	clearCalendar(p_preventOnChange=true) {
		if (p_preventOnChange) {
			this.m_preventOnChange = true;
			this.m_flatpickrInstance.clear();
			this.m_preventOnChange = false;
		} else {
			this.m_flatpickrInstance.clear();
		}
	}
	
	/**
	 * @param {Date} p_date date à switcher
	 * @param {bool} p_preventOnChange si vrais, alors le calendrier sera modifié sans appel à la fonction onChange
	 */
	switchCalendarDate(p_date, p_preventOnChange=true) {
		// Vérifier si la date est déjà sélectionnée
		const isSelected = this.m_flatpickrInstance.selectedDates.some(selectedDate => 
			selectedDate.getTime() === p_date.getTime()
		);

		if (isSelected) {
			// Si la date est sélectionnée, la supprime de la liste
			this.m_flatpickrInstance.setDate(this.m_flatpickrInstance.selectedDates.filter(selectedDate => 
				selectedDate.getTime() !== p_date.getTime()
			), !p_preventOnChange); // `false` pour éviter de déclencher l'événement onChange
		} else {
			// Si la date n'est pas sélectionnée, l'ajouter
			this.m_flatpickrInstance.setDate([...this.m_flatpickrInstance.selectedDates, p_date], !p_preventOnChange);
		}
	}
	
	//------------//
	//-- DIVERS --//
	//------------//
	
	/**
	 * Met à jours l'état actif ou inactif des différentes dates en fonction des dates
	 * contenu dans la liste de dates
	 */
	checkCalendarDateSelected() {
		// On efface le calendrier
		this.clearCalendar();
		
		// On recherche les dates selectionnées et on les passes en actives
		$("#table-salaries-dates-prestations .select-row-date-salarie").each((_, p_element) => {
				const date = new Date($(p_element).attr('data-dateyyyymmdd'));
				this.switchCalendarDate(date);
			}
		);
	}
	
	//------------//
	//-- FORMAT --//
	//------------//
	
	/**
	 * Formatte la date passée en paramètre au format dd/mm/yyyy
	 * @param {Date} p_date à formatter
	 * @returns {String} date formatée
	 */
	formatDateToDDMMYYYY(p_date) {
		const day = String(p_date.getDate()).padStart(2, '0');
		const month = String(p_date.getMonth() + 1).padStart(2, '0'); // Les mois commencent à 0
		const year = p_date.getFullYear();
		return `${day}/${month}/${year}`;
	}
}
