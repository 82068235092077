"use strict";

import { Debug } from './debug.js';

export class PFHBaseClass {

	//---------------------//
	//-- DONNEES MEMBRES --//
	//---------------------//

	m_item = {};
	m_sel = {};
	
	//------------------//
	//-- CONSTRUCTEUR --//
	//------------------//
	
	constructor() {
	}

	//----------------//
	//-- ACCESSEURS --//
	//----------------//

	/**
	 * Récupère l'item dont le nom du sélécteur est passé en paramètre 
	 * en utilisant un cache et donc, sans rechercher dans le DOM à chaques fois
	 * @param {string} p_itemName nom de l'item souhaité
	 * @param {boolean} p_logOnError Si vrai, alors les erreurs sont affichés dans le log
	 * @returns {JQuery Item}
	 */
	I(p_itemName, p_logOnError=true) {
		if (!(p_itemName in this.m_item)) {
			if (p_itemName in this.m_sel) {

				// On créer un nouvel objet Jquery et on l'ajoute
				const $element = $(this.m_sel[p_itemName]);
				if ($element.length > 0) {
					this.m_item[p_itemName] = $element;
				} else {
					if (p_logOnError) {
						Debug.log(`Élément DOM introuvable pour "${p_itemName}" avec "${this.m_sel[p_itemName]}"`);
					}
					return $element;
				}
			} else {
				if (p_logOnError) {
					Debug.log(`L'élément "${p_itemName}" n'existe pas dans m_sel.`);
				}
				return $element;
			}
		}
		return this.m_item[p_itemName];
	}
	
	/**
	 * Récupère l'item dont le nom du sélécteur est passé en paramètre 
	 * SANS utiliser le cache
	 * @param {string} p_itemName nom de l'item souhaité
	 * @param {boolean} p_logOnError Si vrai, alors les erreurs sont affichés dans le log
	 * @returns {JQuery Item}
	 */
	D(p_itemName, p_logOnError=true) {
		// On créer un nouvel objet Jquery et on l'ajoute
		const $element = $(this.m_sel[p_itemName]);
		
		if (!$element.length > 0 && p_logOnError) {
			Debug.log(`Élément DOM introuvable pour "${p_itemName}"`);
		}
		
		return $element;
	}
	
	/**
	 * @param {string} p_itemName nom de l'item souhaité
	 * @returns {string} la chaine contenant le selecteur de l'item dont le nom est passé en paramètre
	 */
	S(p_itemName) {
		return this.m_sel[p_itemName];
	}
}