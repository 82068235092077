/* global disableCarriageReturn */
/* global toastr */
/* global projetSalarie*/
/* global TYPE_SOCIETE*/

"use strict";

import { Debug } from './debug.js';
import { Ajax } from './ajax.js';
import { ListingCadreInfoEmbauche } from './itemCadreInfoEmbauche.js';
import { RechercheSalarie } from './itemRechercheSalarie.js';
import { EditionContrat } from './itemEditionContrat.js';
import { PFHBaseClass } from './pfhBaseClass.js';

window.EMBAUCHE_MODE = {
	RECHERCHE: 1,
	EDITION_CONTRAT: 2,
	EDITION_DEMANDE: 3,
	VALIDATION_DEMANDE: 4
};

export class ProjetCommon extends PFHBaseClass {
	//---------------------//
	//-- DONNEES MEMBRES --//
	//---------------------//
	
	m_tva = 0;
	m_typeDemande = TYPE_SOCIETE.NON_DEFINI;
	
	//------------------//
	//-- CONSTRUCTEUR --//
	//------------------//
	
	constructor() {
		super(); // Appelle le constructeur de PFHBaseClass
	
		const sCreationProjet = "#creationProjet";
	
		this.m_sel = {
			// DIV Global contenant l'ensable du contenu de la création du projet
			creationProjet: `${sCreationProjet}`,
			cadreListingEmbauches: '#declaration-listing-embauche', // Englobe la zone d'affchage du listing des embauches
			boutonsChoixEtapes: `${sCreationProjet} [name="declaration-boutons-etapes"]`,
			boutonsFinaliserDemande: `${sCreationProjet} [name="boutons-finaliser-demande"]`, // Boutons qui demande de passer à sauvegarde de la demande
			boutonsSauvegardeDemande: `${sCreationProjet} [name="boutons-sauvegarde-demande"]`, // Boutons de sauvegarde de la demande
			notesDemande: `${sCreationProjet} [name="declaration-notes"]`,
			notesDemandeTextArea: `${sCreationProjet} [name="notes"]`,
			numObjet: `${sCreationProjet} [name="declaration-num-objet"]`,
			numObjetSelect: `${sCreationProjet} [name="crptIdPrestationEmployeur"]`,
			btnValiderInfosVehicule: '#vehicule-info-valider'
		};
				
		this.m_item =  {
			rechercheSalarie: new RechercheSalarie(),
			editionContrat: new EditionContrat(),
			listingCadreInfoEmbauche: new ListingCadreInfoEmbauche()
		};
	
		// Initialisation de l'objet
		this.setTva(this.iCreationProjet().attr('data-tva-demande'));
		this.iEditionContrat().setConventionCollective(this.idConventionCollective(), this.idAnnexeConventionCollective());
		
		//-------------------------------//
		//-- Configuration des Actions --//
		//-------------------------------//

		/**
		 * On clique sur modifier les infos du véhicule
		 * @param {Event} p_event Evenement associé
		 */
		this.I('btnValiderInfosVehicule').click((p_event) => this.onVehiculeInfosValiderClicked(p_event));
		
		/**
		 * La recherche de salarie est un succès
		 * @param {Event} p_event Evenement associé
		 * @param {JQueryItem} p_item item associé à l'évènement
		 */
		this.iRechercheSalarie().item().on('rechercheSalarieResultClicked', (p_event, p_item) => this.onRechercheSalarieResultClicked(p_event, p_item));
			
		/**
		 * On clique sur l'édition d'un contrat d'embauche 
		 * @param {Event} p_event Evenement associé
		 * @param {CadreInfoEmbauche} p_item CadreInfoEmbauche associé à l'évènement
		 */
		this.iListingCadreInfoEmbauche().item().on('cadreInfoEmbaucheEdited', (p_event, p_item) => this.onCadreInfoEmbaucheEditRequested(p_event, p_item));
		
		//----------------------------------------------//
		//-- Initialisation de l'édition d'un contrat --//
		//----------------------------------------------//
		
		/**
		 * On clique sur le bouton de fermeture de l'édition du contrat d'embauche 
		 * @param {Event} p_event Evenement associé
		 */
		this.iEditionContrat().I('btnFermerContrat').click((p_event) => this.onCloseContratEmbaucheClicked(p_event));

		/**
		 * Gestion des click sur les éléments dynamiques
		 * @param {Event} p_event Evenement associé
		 */
		$(document).on('click', '[name="btn-save-brouillon"], [name="btn-save-demande"]', (p_event) => {
			const $item = $(p_event.currentTarget);
			const name = $item.attr('name');

			switch (name) {
				case 'btn-save-brouillon' :
				case 'btn-save-demande' :
					this.onSaveDemandeClicked(p_event);
					break;
			}
		});
	}
	
	//---------------------------//
	//-- ACCESSEURS SELECTEURS --//
	//---------------------------//
	
	sCreationProjet() {
		return this.S('creationProjet');
	}
	
	//---------------------//
	//-- ACCESSEURS ITEM --//
	//---------------------//

	item() { return this.I('creationProjet'); }
	
	iBoutonsFinaliserDemande() { return this.I('boutonsFinaliserDemande'); }
	
	iCadreListingEmbauches() { return this.I('cadreListingEmbauches'); }
	
	iCreationProjet() { return this.I('creationProjet'); }
	
	iEditionContrat() { return this.m_item.editionContrat; }
	
	iListingCadreInfoEmbauche() { return this.m_item.listingCadreInfoEmbauche; }
	
	iRechercheSalarie() { return this.m_item.rechercheSalarie; }
	
	//----------------//
	//-- ACCESSEURS --//
	//----------------//
	
	idAnnexeConventionCollective() {
		return this.iCreationProjet().attr('data-id-annexe-convention-collective'); 
	}
	
	idConventionCollective() {
		return this.iCreationProjet().attr('data-id-convention-collective'); 
	}

	/**
	 * @returns le type de demande du projet
	 */
	typeDemande() {
		return this.m_typeDemande;
	}

	tva() {
		return this.m_tva;
	}
	
	//-------------//
	//-- SETTERS --//
	//-------------//

	setDefaultLieu(p_defaultLieu) {
		this.iEditionContrat().setDefaultLieu(p_defaultLieu);
	}
	
	setDefaultCodePostal(p_defaultCodePostal) {
		this.iEditionContrat().setDefaultCodePostal(p_defaultCodePostal);
	}
	
	setDefaultVille(p_defaultVille) {
		this.iEditionContrat().setDefaultVille(p_defaultVille);
	}
	
	setDefaultPays(p_defaultPays) {
		this.iEditionContrat().setDefaultPays(p_defaultPays);
	}
	
	/**
	 * @param {Number} p_idAnnexeConventionCollective
	 */
	setIdAnnexeConventionCollective(p_idAnnexeConventionCollective) {
		this.iCreationProjet().attr('data-id-annexe-convention-collective', p_idAnnexeConventionCollective); 
	}
	
	/**
	 * @param {Number} p_idConventionCollective
	 */
	setIdConventionCollective(p_idConventionCollective) {
		this.iCreationProjet().attr('data-id-convention-collective', p_idConventionCollective); 
	}
	
	/**
	 * @param {Number} p_idDemande
	 */
	setIdDemande(p_idDemande) { 
		let newUrl = '';

		switch (this.typeDemande()) {
			case TYPE_SOCIETE.PROD_FH :
			case TYPE_SOCIETE.AUDIOVISUAL :
				newUrl = '/salarie-projet/' + p_idDemande; // On modifie l'url de la page
				break;
			
			case TYPE_SOCIETE.EXTERNALISATION :
				newUrl = '/ext-declaration-demande/' + p_idDemande;
				break;

			default : 
				return TYPE_SOCIETE.NON_DEFINI;
		}
		
		history.pushState(newUrl, '', newUrl);
	}

	/**
	 * @param {Number} p_numDemande
	 */
	setNumDemande(p_numDemande) { 
		//this.iCreationProjet().attr('data-num-demande', p_numDemande); 
		
		var titre = $(this.m_sel.creationProjet + ' [name="titre-creation-demande"]');
		
		switch (this.typeDemande()) {
			case TYPE_SOCIETE.PROD_FH :
			case TYPE_SOCIETE.AUDIOVISUAL :
				if (titre.length === 1) $(titre).html("Modification du Projet n°" + p_numDemande);
				break;
			
			case TYPE_SOCIETE.EXTERNALISATION :
				if (titre.length === 1) $(titre).html("Edition d'une ou des embauche(s) (demande n°" + p_numDemande + ")");
				break;

			default : 
				return TYPE_SOCIETE.NON_DEFINI;
		}
	}
	
	/**
	 * @param p_typeDemande nouveau type de demande
	 */
	setTypeDemande(p_typeDemande) {
		
		// on modifie le type de demande dans le data car il y à une gestion des css avec cela
		this.iCreationProjet().attr('data-type-demande', p_typeDemande);
		
		this.m_typeDemande = Number(p_typeDemande);
			
		let editionContrat = this.iEditionContrat();
		
		switch (this.m_typeDemande) {
			case TYPE_SOCIETE.PROD_FH:
				this.setIdConventionCollective('3090');
				this.setIdAnnexeConventionCollective('4');
				break;

			case TYPE_SOCIETE.AUDIOVISUAL :
				this.setIdConventionCollective('2642');
				this.setIdAnnexeConventionCollective('0');
				break;
		}

		editionContrat.setTypeDemande(p_typeDemande);
		editionContrat.setConventionCollective(this.idConventionCollective(), this.idAnnexeConventionCollective());
		
		this.iCreationProjet().trigger('typeDemandeUpdated', this.m_typeDemande);		
	}
	
	setTva(p_newTva) {
		// Si la TVA est identique on ne fait rien
		var newTva = Number.parseFloat(p_newTva);
		if (newTva === this.tva()) return;
		
		// Sinon on assigne la tva et on fait un trigger
		this.m_tva = newTva;
		this.iCreationProjet().trigger('tvaUpdated', newTva);
	}
	
	//----------------------------//
	//-- CADRES INFOS EMBAUCHES --//
	//----------------------------//
	
	/**
	 * Rafraichis le cadre d'embauche dont l'ID contrat est passé en paramètre
	 * @param {Number} p_idContrat id du contrat
	 * @param {Boolean} p_insertIfNotFound Doit être mis à true si on souhaite ajouter le cadre si il n'as pas été trouver dans la liste
	 * @param {Function} p_successCallback CallBack appelé en cas de succes
	 */
	loadCadreInfoEmbauche(p_idContrat, p_insertIfNotFound=false, p_successCallback=null) {
		
		Ajax.postAsync (
			'AppEmbauche/getTplCadreInfosEmbauche', {
				ajaxRequest: 1,
				idContrat: p_idContrat,
				editMode: 1
			}, 
			
			(p_result) => {				
				// On recherche tous les cadres associés au bon idContrat puis on le rafraichis
				const nbAffected = this.iListingCadreInfoEmbauche().refreshCadreInfoEmbauche(p_result.idContrat, p_result.html);
				
				if (nbAffected === 0 && p_insertIfNotFound) {
					this.iListingCadreInfoEmbauche().insertCadreInfoEmbauche(p_result.html);			
				}
				
				// On appelle le callback
				if (typeof p_successCallback === "function") {
					p_successCallback();
				}
			},
			
			(p_result) => {
				Ajax.defaultAsyncJSONErrorCallback(p_result);
			});
	}
	
	//------------//
	//-- EVENTS --//
	//------------//
	
	/**
	 * Gère le clic de fermeture du contrat d'embauche.
	 * Rafraichis le cadre d'embauche qui est actuellement en cours d'édition
	 * @param {Event} p_event Evenement de clic associé
	 */
	onCloseContratEmbaucheClicked(p_event) {
		p_event.preventDefault();
		
		// On rafraichis ou on ajoute le CadreInfoEmbauche correspondant
		this.loadCadreInfoEmbauche(0, // On utilise le contrat en cours d'édition
		true, // On ajoute le cadre si inexistant
		() => {  // Success callback					
				this.item().trigger('contratEmbaucheClosed'); 
			});
	}
	
	/**
	 * ACTION : DETAIL EMBAUCHE : On clique sur l'icone d'edition
	 * @param p_cadreInfosEmbaucheItem
	 * @param {Event} p_event Evenement associé
	 */
	onCadreInfoEmbaucheEditRequested(p_event, p_cadreInfosEmbaucheItem) {
		p_event.preventDefault();

		this.iEditionContrat().loadContratByIdContrat(p_cadreInfosEmbaucheItem.idContrat());
	}
	
	/**
	 * @param {Event} p_event Evenement associé
	 * @param {DOM} p_item item contenant les données du salarié selectionné
	 */
	onRechercheSalarieResultClicked(p_event, p_item) {
		p_event.preventDefault();
		
		$.blockUI();
		const item = $(p_item); // Convertit `p_item` en objet jQuery 

		// On créer un nouveau contrat que l'on edite
		Ajax.postAsync (
			
			'AppEmbauche/contratCreateNewContrat', {
				idConventionCollective:  this.idConventionCollective(),
				idAnnexeConventionCollective: this.idAnnexeConventionCollective(),
				idSalarieClient: item.attr('data-id-salarie-client'),
				idProfession: item.attr('data-profession'),
				brutDefaut: item.attr('data-brut-defaut'),
				netDefaut: item.attr('data-net-defaut'),
				idFirstProfession: this.iEditionContrat().iProfSelector().idProfession()
			},
			
			(p_result) => {
				// Une fois le contrat ajouté on charge ce dernier
				this.iEditionContrat().loadContratByIdContrat(p_result.idContrat);
				
				// Si une demande à été créer on le défini ici
				if (typeof p_result.idDemande !== 'undefined' && p_result.idDemande !== null) {
					this.setIdDemande(p_result.idDemande);
				}
				
				$.unblockUI();
			},
			
			(p_result) => {
				$.unblockUI();
				Ajax.defaultAsyncJSONErrorCallback(p_result);
			}
		);		
	}
	
	onSaveDemandeClicked(p_event) {
		p_event.preventDefault();
		$.blockUI();

		Ajax.postAsync (
			'AppEmbauche/demandeValider', {
				typeSauvegarde: $(p_event.currentTarget).attr('data-type-sauvegarde'),
				typeDemande: this.typeDemande(),
				crptIdPrestationEmployeur: $(this.m_sel.numObjetSelect).val(),
				notes : $(this.m_sel.notesDemandeTextArea).val()
			},
			
			(p_result) => {
				$.unblockUI();
				toastr.success(p_result.message);
				
				// Si on demande une redirection
				if (p_result.redirect) { setTimeout(function() { window.location = p_result.redirect; }, 2000); } 
			},
			
			(p_result) => { 
				$.unblockUI();
				Ajax.defaultAsyncJSONErrorCallback(p_result);
			}
		);
	}
	
	onVehiculeInfosValiderClicked() {
		var puissFiscale = parseInt($('#vehicule-info-pf').val());
		var vehicule = $('#vehicule-info-model').val();
		
		if (isNaN(puissFiscale) || puissFiscale <= 0 || puissFiscale > 99) {
				toastr.error("La puissance fiscale doit être comprise dans l'interval 1 à 99");
				return;
		}
		
		if (vehicule.trim().lengt <= 1) {
				toastr.error("Merci de spécifier le modèle");
				return;
		}

		Ajax.postAsync (
			'AppSalarie/changerSalarieParIdSalarieClient', {
				idSalarieClient: this.iEditionContrat().idSalarieClient(), // ATTENTION !!!
				puissFiscale: puissFiscale,
				vehicule: vehicule
			}, 
			
			() => {
				$('#vehicule-info').modal('hide');
				this.iEditionContrat().trigger('contratEmbaucheCalculFraisKms'); // On lance le calcul
			},
			
			Ajax.defaultAsyncJSONErrorCallback
		);
	}
};

