"use strict";

import { Ajax } from './ajax.js';
import { Dom } from './dom.js';
import { Debug } from './debug.js';
import { PFHBaseClass } from './pfhBaseClass.js';

/**
 * Gestion d'un objet de type cadre info embauche
 * @type type
 */
export class CadreInfoEmbauche {

	//-------------------------------//
	//-- DONNEES MEMBRES STATIQUES --//
	//-------------------------------//
	
	static m_domName = 'cadre-info-embauche'; // Nom des items dans le dom
	
	//----------------------//
	//-- DONNEES MEMBRES  --//
	//----------------------//

	m_item = {
		cadreInfoEmbauche: null
	}

	//------------------//
	//-- CONSTRUCTEUR --//
	//------------------//

	constructor(p_cadreInfoEmbauche) {
		this.m_item.cadreInfoEmbauche = p_cadreInfoEmbauche;
  }

	//----------------//
	//-- ACCESSEURS --//
	//----------------//
	
	item() {
		return this.m_item.cadreInfoEmbauche;
	}
	
	static domName() {
		return this.m_domName;
	}
	
	idContrat() {
		return this.item().attr('data-id-contrat');
	}
	
	//------------//
	//-- DIVERS --//
	//------------//
		
	/**
	 * Rafraîchit un cadre d'embauche avec du HTML donné
	 * @param {jQuery} p_cadreInfoEmbaucheItem - L'élément cadre à rafraîchir
	 * @param {string} p_newItemHtml - Le HTML de remplacement
	 */
	static refreshCadreInfoEmbaucheItemWithHtml(p_cadreInfoEmbaucheItem, p_newItemHtml) {

		// Convertir le HTML en un objet jQuery pour manipulation
		const $parsedHtml = $(p_newItemHtml);

		// Vérifier que l'élément a bien des enfants (contenu valide)
		if ($parsedHtml.length === 0 || !$parsedHtml.is('div')) {
			Debug.log('Invalid HTML structure or root is not a <div>.');
			return;
		}
		
		// On remplace le HTML avec le contenu interne du <div>
		p_cadreInfoEmbaucheItem.html($parsedHtml.html());
	}
	
	/**
	 * Rafraîchit un cadre d'embauche en utilisant un ID de contrat pour charger les données
	 * @param {jQuery} p_cadreInfoEmbaucheItem - L'élément cadre à rafraîchir
	 */
	static refreshCadreInfoEmbaucheItem(p_cadreInfoEmbaucheItem) {
		const idContrat = parseInt(p_cadreInfoEmbaucheItem.attr("data-id-contrat"));

		if (!isNaN(idContrat)) {
			Ajax.postAsync(
				'AppEmbauche/getTplCadreInfosEmbauche', {
					ajaxRequest: 1,
					idContrat: idContrat,
					editMode: 1
				}, 
				
				(p_result) => CadreInfoEmbauche.refreshCadreInfoEmbaucheItemWithHtml(p_cadreInfoEmbaucheItem, p_result.html),
				
				(p_result) => Ajax.defaultAsyncJSONErrorCallback(p_result)
			);
		}
	}
}

export class ListingCadreInfoEmbauche extends PFHBaseClass {
	
	//------------------//
	//-- CONSTRUCTEUR --//
	//------------------//
	
	constructor() {
		super(); // Appelle le constructeur de PFHBaseClass
		
		// Initialisation des sélécteurs
		this.m_sel = {
			mainItem: '#listing-cadre-info-embauche',
			lstCadreInfoEmbauche: `#listing-cadre-info-embauche [name="${CadreInfoEmbauche.domName()}"]` // Listing des cadres infos embauches (dynamiques
		};
	
		/**
		 * Gestion des click sur les éléments dynamiques
		 * @param {Event} p_event Evenement associé
		 */
		$(document).on('click', '[name="btn-remove-embauche"], [name="btn-edit-embauche"]', (p_event) => {
			const $item = $(p_event.currentTarget);
			const name = $item.attr('name');

			switch (name) {
				case 'btn-remove-embauche' :
					const idContrat = $item.attr('data-id-contrat');
					this.onSupprimerEmbaucheClicked(p_event, idContrat);
					break;
					
				case 'btn-edit-embauche' :
					this.onEditerEmbaucheClicked(p_event, $item);
					break;
			}
		});
	}
	
	//----------------//
	//-- ACCESSEURS --//
	//----------------//
	
	item() {
		return this.I('mainItem');
	}
	
	/**
	 * Retourne le cadre associé à l'idContrat passé en paramètre
	 * @param {number} p_idContrat Id du contrat associé au cadre
	 * @returns {jQuery} Le cadre d'embauche correspondant
	 */
	getCadre(p_idContrat) {
		return $(`[name="${CadreInfoEmbauche.domName()}"][data-id-contrat="${p_idContrat}"]`);
	}
	
	//------------//
	//-- DIVERS --//
	//------------//
	
	/**
	 * Compte le nombre de cadres d'embauche dans l'élément spécifié
	 * @returns {number} le nombre de cadres d'embauches contenu dans un élément
	 */
	embaucheCount() {
		let count = 0;
		
		this.D('lstCadreInfoEmbauche').each(function() {
			count = count+1; 
		});
		
		return count;
	}
		
	/**
	 * Insère un nouveau cadre d'info d'embauche dans l'élément spécifié
	 * @param {string} p_itemHtml HTML du cadre à insérer
	 * @param {boolean} p_showListingAfterInsertion Si vrais, alors on force l'affichage du listing après son insertion
	 * @returns {jQuery} L'élément inséré
	 */
	insertCadreInfoEmbauche(p_itemHtml, p_showListingAfterInsertion=true) {	
		if (!p_itemHtml) {
			return;
		}
		
		const newItem = $('<div></div>').html(p_itemHtml).find('>:first-child');
		
		const $listing = this.item();
		
		$listing.append(newItem);
		$listing.append('<br />');
		
		// On affiche le cadre global
		if (p_showListingAfterInsertion) {
			Dom.setVisibility(this.item(), true);
		}
		
		return newItem;
	}
	
	//------------//
	//-- EVENTS --//
	//------------//
	
	/**
	 * Déclenche l'édition du cadre d'embauche
	 * @param {Event} p_event - Événement associé
	 * @param {jQuery} p_cadreInfosEmbaucheItem - L'élément du cadre d'embauche
	 */
	onEditerEmbaucheClicked(p_event, p_cadreInfosEmbaucheItem) {
		p_event.preventDefault();
		this.item().trigger('cadreInfoEmbaucheEdited', new CadreInfoEmbauche(p_cadreInfosEmbaucheItem));
	}
	
	/**
	 * Action de suppression d'un cadre d'embauche
	 * @param {Event} p_event - Événement associé
	 * @param {number} p_idContrat - ID du contrat concerné
	 */
	onSupprimerEmbaucheClicked (p_event, p_idContrat) {
		p_event.preventDefault();
		
		const cadreInfosEmbaucheItem = this.getCadre(p_idContrat);

		Ajax.postAsync(
			'AppEmbauche/contratSupprimer', 
			{ idContrat: p_idContrat }, 
			
		(p_result) => {
				cadreInfosEmbaucheItem.remove();
				this.item().trigger('cadreInfoEmbaucheRemoved');
				Ajax.defaultAsyncJSONSuccessCallback(p_result);
			},
			
			(p_result) => Ajax.defaultAsyncJSONErrorCallback(p_result)
		);
	}
	
	//---------//
	//-- GUI --//
	//---------//
	
	/**
	 * Rafraîchit tous les cadres d'embauche dans un élément donné
	 */
	refreshAllCadreInfoEmbauche() {
		// On rafraichis chacuns des cadres
		this.D('lstCadreInfoEmbauche').each((_, elem) => {
			CadreInfoEmbauche.refreshCadreInfoEmbaucheItem($(elem));		
		});
	}
	
	/**
	 * Rafraîchit un cadre d'embauche avec un contenu HTML spécifique basé sur l'ID du contrat
	 * @param {number} p_idContrat - ID du contrat
	 * @param {string} p_newItemHtml - HTML de remplacement
	 * @returns {number} Indique le nombre d'éléments modifiés
	 */
	refreshCadreInfoEmbauche (p_idContrat, p_newItemHtml) {
		let nbItemAffected = 0;
		const idContrat = parseInt(p_idContrat);

		// On recherche tous les cadres associés au bon idContrat puis on le rafraichis
		this.D('lstCadreInfoEmbauche').each((_, p_item) => {
			const currentIdContrat = parseInt($(p_item).attr("data-id-contrat"));
			
			if (currentIdContrat === idContrat) {
				nbItemAffected++;
				CadreInfoEmbauche.refreshCadreInfoEmbaucheItemWithHtml($(p_item), p_newItemHtml);
			}			
		});
		
		return nbItemAffected;
	}
}