"use strict";

/* Section pour virer les warnings de netbeans concernant les variables globales*/
/* global toastr */

import { Ajax } from './ajax.js';
import { Dom } from './dom.js';
import { Slider } from './slider.js';
import { Debug } from './debug.js';
import { PFHBaseClass } from './pfhBaseClass.js';

class CompteCommon extends PFHBaseClass {
	//------------------//
	//-- CONSTRUCTEUR --//
	//------------------//
	
	constructor() {
		super(); // Appelle le constructeur de PFHBaseClass	
		
		this.m_sel = {
			// Singles
			formConnexion : '#form-connexion',
			formContact: '#form-contact',
			formPerteIdentifiant: '#form-perte-identifiant',
			formPerteMotDePasse : '#form-perte-mot-de-passe',

			// Listings
			lstBtnDeconnexion: '[name="appDeconnexion"]',
			lstQuestion: '[name="question"]',
			lstBtnPasswordSwitch: '[name="password-switch"]'
		};
		
		const pageCourante = Dom.getPageName();
	
		this.initApi();
		
		switch (pageCourante) {
		
			//--------------------------//
			//-- SECTION SE CONNECTER --//
			//--------------------------//

			//-- PAGE CONNEXION --//
			case 'login': this.initPageLogin(); break;
				
			//---------------------------------//
			//-- SECTION MOT DE PASSE OUBLIE --//
			//---------------------------------//

			//-- PAGE MOT DE PASSE OUBLIE --//
			case 'mot-de-passe-oublie': this.initPageMdpOublie(); break;
				
			//-- PAGE IDENTIFIANT OUBLIE --//
			case 'identifiant-oublie' : this.initPageIdentifiantOublie(); break;			
	
			//----------------------------//
			//-- SECTION NOUS CONTACTER --//
			//----------------------------//

			//-- PAGE CONTACT --//
			case 'contact' : this.initPageContact(); break;
				
			//-----------------------//
			//-- SECTION MENU HAUT --//
			//-----------------------//
		
			//-- PAGE SPECTACLE VIVANT --//		
			case 'productions-hanouna' : this.initPageProdFh(); break;
			
			//-- PAGE SIMPLE PAIE --//
			case 'simplepaye' : this.initPageSimplePaie(); break;
		}
		
		//----------------------//
		//-- TOUTES LES PAGES --//
		//----------------------//
		
		this.initCommonPages();
	}
	
	//----------------//
	//-- EVENEMENTS --//
	//----------------//
	
	/**
	 * Affiche ou fait disparaitre la réponse d'une question lorsque l'on clique dessus
	 * L'élément cliqué doit posséder la donnée data-numero qui est associe à un 
	 * autre élément à afficher ou cacher dont l'id est : reponse-question<numQuestion>
	 * @param {Event} p_event Evenement associé
	 */
	toggleOnClick(p_event) {
		p_event.preventDefault();
		const numQuestion = $(p_event.currentTarget).attr('data-numero');
		$('#reponse-question' + numQuestion).toggle(1000);
	}
	
	//---------------------//
	//-- INITIALISATIONS --//
	//---------------------//
	
	//-- INITIALISATIONS DES API DE LA PAGE --//
	initApi() {
		// Init blockUI
		$.blockUI.defaults.css = {};
		$.blockUI.defaults.baseZ = 2147483647;//Know bug on Firefox + chrome z-index:2147483647 max value on this OS
		$.blockUI.defaults.message = '<img src="' + SITE_URL + 'themes/www/img/ajax-loader-black.png' + '" alt="">';

		//Init toastr
		toastr.options = {
			"closeButton": true,
			"debug": false,
			"positionClass": "toast-top-full-width",
			"onclick": null,
			"showDuration": "1000", // Temps d'apparition
			"hideDuration": "1000", // Temps de disparition
			"timeOut": Ajax.toastrMessagesDuration(), 
			"extendedTimeOut": "1000" // Temps d'apparition supplémentaire si l'utilisateur quitte le bandeau avec la souris
		};
	}
	
	initCommonPages() {
		/**
		 * On clique sur un lien de déconnexion
		 * @param {Event} p_event Evenement associé
		 */
		this.I('lstBtnDeconnexion', false).click((p_event) => {
			p_event.preventDefault();

			Ajax.postAsync(
				'AppCompte/logout',
				{},
				(p_result) => Ajax.defaultAsyncJSONSuccessCallback(p_result),
				(p_result) => Ajax.defaultAsyncJSONErrorCallback(p_result)
			);
		});
	
		/**
		 * Complément de $(document).on('input', '[data-input-format]', function (p_event)
		 * @param {Event} p_event Evenement associé
		 */
		$(document).on('keydown', '[data-input-format]', (p_event) => {
			const $target = $(p_event.target);
			const format = $target.attr('data-input-format');
			let value = $target.val();

			if (format === 'decimal-positive') {
				const cursorPosition = this.selectionStart;
				const hasADot = value.includes(',') || value.includes('.');
				const key = p_event.key;//const charCode = p_event.which || p_event.keyCode;

				// Empêcher l'ajout de '.' ou ',' si déjà présent ou en première position
				if ((key === '.' || key === ',') && (hasADot || cursorPosition === 0)) {
					p_event.preventDefault();
					return false;
				}
			}
		});

		// On lance la vérification des éléments qui contiennent des valeures requises
		$(document).on('input', '[data-required]', function() {
			Dom.checkDataRequired($(this)); // Appelle checkDataRequired avec le contexte correct
		});

		/**
		 * Pour chaques champs on régarde si on doit appliquer le highlight
		 */
		$(".form-control[data-required]").each(function () {
			Dom.checkDataRequired($(this)); // Appelle checkDataRequired avec le contexte correct
		});

		/**
		 * Gestion dynamiques des saisies au clavier en fonction du format souhaité
		 * Le format est défini en ajoutant data-input-format="<format>"
		 * Les formats supportés sont :
		 * - uppercase : La saisie passe en majuscule
		 * - lowercase : La saisie passe en minuscule
		 * - code-postal : La saisie suit un format de type code postal
		 * - decimal-positive : La saisie doit suivre un format de type décimal positif
		 * @param {Event} p_event Evenement associé
		 */
		$(document).on('input', '[data-input-format]', (p_event) => {
			const $target = $(p_event.target);
			const format = $target.attr('data-input-format');
			let value = $target.val();

			switch (format) {
				case 'uppercase':
					$target.val(value.toUpperCase());
					break;
					
				case 'lowercase':
					$target.val(value.toLowerCase());
					break;
					
				case 'code-postal':
					value = value.toUpperCase().replace(/[^0-9AB]/g, ''); // Conserver uniquement les caractères valides pour les codes postaux
					$target.val(value);
					break;
					
				case 'decimal-positive':
					// On remplace de value les , par des .
					value = value.replace(/,/g, '.');

					// On supprime de value tous les cractères autres que des chiffres et des points
					value = value.replace(/[^0-9.]/g, '');

					$target.val(value);
					break;
			}
		});
	
		// Initialisation des tooltips Bootstrap
		$('[data-bs-toggle="tooltip"]').tooltip({
				offset: [0, 10] // Décalage du tooltip de 10px vers la droite ou le bas selon la position
			});
	}
	
	//-- PAGE CONTACT --//
	initPageContact() {
		
		// Envoi d'un mail via la page contact
		Dom.initFormValidation(
			this.this.I('formContact'), 
			'AppContact/sendMail',
			{
				nom_prenom: { required: true },
				telephone: { required: true, number: true },
				email: { required: true, email: true, valid_email: true, strtolower: true },
				message: { required: true }
			},
			{
				nom_prenom: { required: "Merci de renseigner votre nom/prénom" },
				telephone: { required: "Merci de renseigner votre numéro de téléphone", number: "Merci de renseigner des valeurs numériques" },
				email: { required: "Merci de renseigner votre email", email: "Merci de renseigner un email valide" },
				message: { required: "Merci de renseigner votre message" }
			});
	}
	
	//-- PAGE DE CONNEXION --//
	initPageLogin() {
		
		// Configuration du formulaire de connexion
		Dom.initFormValidation(
			this.I('formConnexion'),
			'AppCompte/login', 
			{
				identifiant: { required: true },
				mot_de_passe: { required: true },
				connexion_auto: { number: true }
			},
			{
				identifiant: { required: "Merci de renseigner un identifiant" },
				mot_de_passe: { required: "Merci de renseigner un mot de passe" },
				connexion_auto: { number: "Merci de renseigner une valeur numérique" }
			});
		
		// On clique sur le bouton de visibilité du mot de passe
		this.I('lstBtnPasswordSwitch').click((p_event) => {			
			Dom.togglePassword(p_event);
		});
	}
	
	//-- PAGE IDENTIFIANT OUBLIE --//
	initPageIdentifiantOublie() {
		
		// Configuration du formulaire de perte d'identifiant
		Dom.initFormValidation(
			this.I('formPerteIdentifiant'),
			'AppCompte/envoyerMailPerteIdentifiant', 
			{ email: { required: true } }, 
			{ email: { required: "Merci de renseigner votre email" } });
	}
				
	//-- PAGE MOT DE PASSE OUBLIE --//
	initPageMdpOublie() {

		// Configuration du formulaire de perte de mot de passe
		Dom.initFormValidation(
			this.I('formPerteMotDePasse'),
			'AppCompte/envoyerMailPerteMotDePasse', 
			{ identifiant: { required: true } }, 
			{ identifiant: { required: "Merci de renseigner votre identifiant" } });
	}
	
	//-- PAGE SPECTACLE VIVANT --//
	initPageProdFh() {
		
		// Active le switch des questions sur cette page
		this.I('lstQuestion').click((p_event) => this.toggleOnClick(p_event));
			
		// On active le slider
		let slider = new Slider("slideshow-container", "slideshow-dot-container");
		slider.showSlidesAuto();
	}
	
	//-- PAGE SIMPLE PAIE --//
	initPageSimplePaie() {
		
		// Active le switch des questions sur cette page
		this.I('lstQuestion').click((p_event) => this.toggleOnClick(p_event));
	}
}

//--------------------------//
//-- APPLICATION GENERALE --//
//--------------------------//

/**
 * Le DOM est chargé, on charge le javascript
 */
$(document).ready(function () {
	const compteCommon = new CompteCommon();
});